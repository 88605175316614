import { render, staticRenderFns } from "./comment-audit.vue?vue&type=template&id=9aee05a8&scoped=true"
import script from "./comment-audit.vue?vue&type=script&lang=js"
export * from "./comment-audit.vue?vue&type=script&lang=js"
import style0 from "./comment-audit.vue?vue&type=style&index=0&id=9aee05a8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9aee05a8",
  null
  
)

export default component.exports