import Vue from 'vue';
import _ from 'lodash';
import filters from '@/shared/filters';
//#region Stores
import { useInsightsStore } from '@/stores/insights-store';
import { useCommonStore } from '@/stores/common-store';
import { useGroupsFilterStore } from '@/stores/groups-store';
import { useLocationStore } from '@/stores/location-store';
import { useDriverStore } from '@/stores/driver-store';
import { useAssetStore } from '@/stores/asset-store';
//#endregion
//#region Services
import vehicleService from "@/service/vehicles.service";
import { CommonService, ConfigurationService } from "../service";
import LocationService from "../service/locations.service";
import { LiveMapService } from "../services";

//#endregion

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
const batchSize = {
    vehicles: 200,
    drivers: 100,
    locations: 40,
};
const createLogs = true;
const state = () => ({
    vehicles: [],
    drivers: [],
    locations: [],
    tollgates: [],
    tenantId: "",
});

const getters = {};

const actions = {
    //#region Main Call
    async getAll({ commit, state, dispatch }, { filteredAssets = [], filteredOperators = [] }) {
        //So It Seems
        //If it's in the state - we dont refresh :/
        //The updating of assets happens in a foreach - this may be optimized
        createLogs && console.log("LiveMap GetAll Called");
        const insightsStore = useInsightsStore();
        const groupFilterStore = useGroupsFilterStore();
        const locationStore = useLocationStore();
        const driverStore = useDriverStore();
        const vehicleStore = useAssetStore();
        var pendingList = {
            vehiclesPartial: [],
            vehiclesFull:[],
            drivers: [],
            locations: [],
        };
        dispatch('tenantValidation');
        //#region Tollgates
        dispatch('loadTollgates');
        //#endregion
        //#region Locations
        if (locationStore.locations.length == 0) {
            //Locations Store is Empty
            if (groupFilterStore.locations.length > 0) {
                //It is possible for groups to be "ALL" which will result in a massive call - need a way to breakdown this list
                let locationsInState = state.locations.map(i => i.id);
                //pendingList.locations = [...groupFilterStore.locations].filter(locationId => !locationsInState.includes(locationId));
                //We would Only request locations not in the state, but this could result in the location becoming stale, so changes made to sort instead of filter
                pendingList.locations = [
                    ...groupFilterStore.locations.filter(locationId => !locationsInState.includes(locationId)),
                    ...locationsInState,
                ];
            } else {
                //Groups is empty for locations
                commit('setLocations', []);
                createLogs && console.log("Locations Empty in Store and Groups");
            };
        } else {
            //Locations Exist in the store
            commit('setLocations', locationStore.locations);
            pendingList.locations = locationStore.locations.map(i => i.id);
            createLogs && console.log("Locations Set From Store");
        };
        //#endregion
        //#region Operators
        if (driverStore.operators.length == 0) {
            //Operator Store is Empty
            if (groupFilterStore.drivers.length > 0) {
                let driversInState = state.drivers.map(i => i.id);
                //pendingList.drivers = [...groupFilterStore.drivers].filter(driverId => !driversInState.includes(driverId));
                //We would Only request operators not in the state, but this could result in the operator becoming stale, so changes made to sort instead of filter
                pendingList.drivers = [
                    ...groupFilterStore.drivers.filter(driverId => !driversInState.includes(driverId)),
                    ...driversInState,
                ];
            } else {
                //Groups is empty for Operators
                commit('setDrivers', []);
                createLogs && console.log("Operators Empty in Store and Groups");
            };
        } else {
            //Operators Exist in the store
            dispatch('applyOperators', driverStore.operators);
            pendingList.drivers = driverStore.operators.map(i => i.id);
            createLogs && console.log("Operators Set From Store");
        };
        //#endregion
        //#region Assets
        if (vehicleStore.getAssetsSelectorData().length == 0) {
            if (groupFilterStore.vehicles.length > 0) {
                await vehicleService.getAssetLight({ ids: groupFilterStore.vehicles }).then(lightAssets => {
                    if (lightAssets.length > 0) {
                        //Update Store
                        createLogs && console.log("Updating Asset store with getAssetLight response");
                        vehicleStore.replaceAssetsLightState(lightAssets);
                        //#region Set Starting List (Used for selection and Chunk Generation)
                        let loadedIds = state.vehicles.filter(i => i.preloaded != true).map(i => i.id);
                        let tempAssets = lightAssets.map(i => {
                            if (loadedIds.includes(i.id)) { //Maintain values where already in state
                                return state.vehicles.find(l => l.id == i.id);
                            } else { // Load rest as preload
                                return {
                                    ...i,
                                    preloaded: true,
                                    lastPosition: {
                                        bpStatusAttribute: 0,
                                        lastKnownEventTime: new Date(),
                                        status: 0,
                                    },
                                };
                            };
                        });
                        commit('setVehicles', tempAssets);
                        //#endregion
                        //#region Filter request if Filter Provided
                        let filteredResults = tempAssets
                            .filter(i => filteredAssets.length != 0
                                ? filteredAssets.includes(i.id)
                                : true);
                        pendingList.vehiclesPartial = filteredResults.filter(i => !i.preloaded).map(i => i.id);
                        pendingList.vehiclesFull = filteredResults.filter(i => i.preloaded).map(i => i.id);
                        //#endregion
                    } else {
                        commit('setVehicles', []);
                        insightsStore.trackError('LiveMap', 'No Assets');
                        createLogs && console.log("LiveMap getAssetLight returned no results");
                    };
                }).catch(err => {
                    commit('setVehicles', []);
                    insightsStore.trackError('LiveMap', 'getAssetLight', err);
                    createLogs && console.log("Livemap getAssetLight call failed", err);
                });
            } else {
                commit('setVehicles', []);
                insightsStore.trackError('LiveMap', 'No Assets');
                createLogs && console.log("Assets Empty in Store and Groups");
            };
        } else {
            //Assets Exist in the store
            let loadedIds = state.vehicles.filter(i => i.preloaded != true).map(i => i.id);
            let tempAssets = vehicleStore.getAssetsSelectorData().map(i => {
                if (loadedIds.includes(i.id)) { //Maintain values where already in state
                    return state.vehicles.find(l => l.id == i.id);
                } else { // Load rest as preload
                    return {
                        ...i,
                        preloaded: true,
                        lastPosition: {
                            bpStatusAttribute: 0,
                            lastKnownEventTime: new Date(),
                            status: 0,
                        },
                    };
                };
            });
            commit('setVehicles', tempAssets);
            //#region Filter request if Filter Provided
            let filteredResults = tempAssets
                .filter(i => filteredAssets.length != 0
                    ? filteredAssets.includes(i.id)
                    : true);
            pendingList.vehiclesPartial = filteredResults.filter(i => !i.preloaded).map(i => i.id);
            pendingList.vehiclesFull = filteredResults.filter(i => i.preloaded).map(i => i.id);
            createLogs && console.log("Assets Set from Store");
            //#endregion
        };
        //#endregion
        //#region Chunking Data
        createLogs && console.log("Chunking Calls");
        let chunks = [];
        chunks.push({
            addStaticData : pendingList.vehiclesFull.length > 0,
            vehicles: pendingList.vehiclesFull.length > 0
                ? pendingList.vehiclesFull.splice(0, Math.floor(batchSize.vehicles / 2))
                : pendingList.vehiclesPartial.length > 0
                    ? pendingList.vehiclesPartial.splice(0, Math.floor(batchSize.vehicles / 2))
                    : [],
            drivers: pendingList.drivers
                ? pendingList.drivers.splice(0, Math.floor(batchSize.drivers / 2))
                : [],
            locations: pendingList.locations
                ? pendingList.locations.splice(0, Math.floor(batchSize.locations / 2))
                : [],
        });
        while (pendingList.vehiclesPartial.length > 0
            || pendingList.vehiclesFull.length > 0
            || pendingList.drivers.length > 0
            || pendingList.locations.length > 0) {
            chunks.push({
                addStaticData: pendingList.vehiclesFull.length > 0,
                vehicles: pendingList.vehiclesFull.length > 0
                    ? pendingList.vehiclesFull.splice(0, batchSize.vehicles)
                    : pendingList.vehiclesPartial.length > 0
                        ? pendingList.vehiclesPartial.splice(0, batchSize.vehicles)
                        : [],
                drivers: pendingList.drivers
                    ? pendingList.drivers.splice(0, batchSize.drivers)
                    : [],
                locations: pendingList.locations
                    ? pendingList.locations.splice(0, batchSize.locations)
                    : [],
            });
        };
        //#endregion
        for (var batch of chunks) {
            createLogs && console.log(["Calling Batch [", [batch.vehicles.length, batch.drivers.length, batch.locations.length,batch.addStaticData].join(":"), "]"].join(""));
            LiveMapService.getAll(batch).then((liveMap) => {
                //#region Locations
                if (liveMap.locations.length > 0) {
                    createLogs && console.log("Processing Locations");
                    liveMap.locations.forEach(location => {
                        commit('addLocation', location);
                    });
                };
                //#endregion
                //#region Drivers
                if (liveMap.drivers.length > 0) {
                    createLogs && console.log("Processing Drivers");
                    dispatch('applyOperators', liveMap.drivers);
                };
                //#endregion
                //#region vehicles
                if (liveMap.vehicles.length > 0) {
                    createLogs && console.log("Processing Assets");
                    let parsedVehicles = liveMap.vehicles.map(asset => {
                        return {
                            ...asset,
                            profileImage: filters.methods.$getBlobStorageImage(asset.profileImage),
                            vehicleIcon: filters.methods.$getBlobStorageImage(asset.vehicleIcon),
                            driver: asset?.driver?.driverId
                                ? {
                                    ...asset.driver,
                                    profileImage: asset.driver ? filters.methods.$getBlobStorageImage(asset.driver.profileImage) : null
                                }
                                : state.drivers.find(i => i.id == asset.driverId) || asset.driver
                        };
                    });
                    parsedVehicles.forEach(asset => {
                        commit('addVehicle', asset);
                    });
                };
                //#endregion
            });
        };
    },
    //#endregion
    //#region Operator Processing
    applyOperators({ commit, state }, operatorList = []) {
        let drivers = operatorList.length > 0
            ? operatorList.map(operator => {
                return {
                    ...operator,
                    profileImage: filters.methods.$getBlobStorageImage(operator.profileImage),
                }
            })
            : [];
        drivers.forEach(driver => {
            commit('addDriver', driver);
        });
        //#region Apply to Assets already Loaded
        let operatorIdList = drivers.map(i => i.id);
        state.vehicles.filter(i => i.driver && !i.driver.driverId && operatorIdList.includes(i.driverId))
            .map(asset => ({
                ...asset,
                driver: drivers.find(i => i.id == asset.driverId) || asset.driver
            })).forEach(asset => {
                commit('addVehicle', asset);
            });
        //#endregion
    },
    //#endregion
    //#region Individual Calls
    loadTollgates({ commit }) {
        const insightsStore = useInsightsStore();
        const commonStore = useCommonStore();
        if (commonStore.getTollgates().length == 0) {
            createLogs && console.log("Calling server for Tollgates");
            CommonService.getTollgates().then((result) => {
                commit('setTollgates', result);
                commonStore.setTollgates(result);
                createLogs && console.log("Tollgates Set", result);
            }).catch(err => {
                insightsStore.trackError('LiveMap', 'setTollgates', err);
                createLogs && console.log("Failed to set Tollgates", err);
            });
        } else {
            commit('setTollgates', commonStore.getTollgates());
            createLogs && console.log("Tollgates set from store", commonStore.getTollgates());
        };
    },
    getLocations({ commit }) {
        const insightsStore = useInsightsStore();
        createLogs && console.log("Livemap/getLocations called");
        const locationStore = useLocationStore();
        if (locationStore.getLocationsData().length == 0) {
            createLogs && console.log("Location Store Empty - Calling server");
            LocationService.searchByGroupIds().then(results => {
                locationStore.replaceLocationState(results.items);
                commit('setLocations', results.items);
                createLogs && console.log("Locations Set From Server Response");
            }).catch(err => {
                insightsStore.trackError('LiveMap', 'getLocations', err);
                createLogs && console.log("Locations Server Call Failed", err);
                //We could clear the location state at this point, but rather leave it intact to minimize impact of the failed call.
            });
        } else {
            commit('setLocations', locationStore.getLocationsData());
            createLogs && console.log("Locations set from store");
        };
    },
    getTollgates({ commit }) {
        const commonStore = useCommonStore();
        if (commonStore.getTollgates().length == 0) {
            CommonService.getTollgates().then((result) => {
                commit('setTollgates', result);
                commonStore.setTollgates(result);
            });
        } else {
            commit('setTollgates', commonStore.getTollgates());
        };
    },
    async getSelectedAsset({ commit, state }, { pendingAssets }) {
        const insightsStore = useInsightsStore();
        createLogs && console.log("LiveMap/getSelectedAsset called", pendingAssets);
        let assetsInState = state.vehicles.filter(asset => pendingAssets.includes(asset.id) && !asset.preloaded).map(i => i.id);
        let assetsNotInState = pendingAssets.filter(assetId => !assetsInState.includes(assetId));
        let chunks = [];
        while (assetsInState.length > 0 || assetsNotInState.length > 0) {
            chunks.push({
                addStaticData: assetsNotInState.length > 0,
                vehicles: assetsNotInState.length > 0
                    ? assetsNotInState.splice(0, batchSize.vehicles)
                    : assetsInState.length > 0
                        ? assetsInState.splice(0, batchSize.vehicles)
                        : [],
                drivers: [],
                locations: [],
            });
        };
        createLogs && console.log([chunks.length, "Chunks Generated to call"].join(" "));
        for (var batch of chunks) {
            createLogs && console.log("Calling Chunk", batch);
            LiveMapService.getAll(batch).then((result) => {
                let parsedVehicles = result.vehicles.map(asset => {
                    return {
                        ...asset,
                        profileImage: filters.methods.$getBlobStorageImage(asset.profileImage),
                        vehicleIcon: filters.methods.$getBlobStorageImage(asset.vehicleIcon),
                        driver: asset?.driver?.driverId
                            ? {
                                ...asset.driver,
                                profileImage: asset.driver ? filters.methods.$getBlobStorageImage(asset.driver.profileImage) : null
                            }
                            : state.drivers.find(i => i.id == asset.driverId) || asset.driver
                    };
                });
                parsedVehicles.forEach(asset => {
                    commit('addVehicle', asset);
                });
                createLogs && console.log("Chunk Processed", parsedVehicles);
            }).catch(err => {
                insightsStore.trackError('LiveMap', 'getSelectedAsset', err);
                createLogs && console.log("getSelectedAsset Call Failed", batch, err);
            });
        };
    },
    //#endregion
    //#region SignalR
    processSignalRQue({ commit, state }, signalRQue) {
        signalRQue.forEach(q => {
            commit('updateVehicle', q);
        });
    },
    //#endregion
    tenantValidation({ commit, state }) {
        let tenantId = Vue.prototype.$session.get('tenantDetails')
            ? Vue.prototype.$session.get('tenantDetails').tenantId
            : ''
        createLogs && console.log("Comparing Tenants", state.tenantId, tenantId)
        if (state.tenantId != tenantId) {
            createLogs && console.log("Clearing Store");
            commit('clearStore');
        };
        commit('setTenant', tenantId);
    },
    clearStore({ commit }) {
        commit('clearStore');
    },
};

const mutations = {
    //#region Tollgates
    setTollgates(state, tollgates) {
        state.tollgates = tollgates;
    },
    //#endregion
    //#region Locations
    addLocation(state, location) {
        if (location.type === 'CIRCLE' || location.type === 'MARKER' || location.type === 'POINT') {
            location.position = location.position
                ? {
                    ...location.position,
                    lat: location.position.lat || location.position.latitude || 0,
                    lng: location.position.lng || location.position.longitude || 0,
                }
                : {
                    lat: 0,
                    lng: 0,
                };
            if (Math.abs(location.position.lat) > 90) {
                location.position.lat = 0;
            };
            if (Math.abs(location.position.lng) > 90) {
                location.position.lat = 0;
            };
        };
        let index = state.locations.map(i => i.id).indexOf(location.id);
        if (index != -1) {
            state.locations.splice(index, 1);
        };
        state.locations.push(location);
    },
    setLocations(state, locations) {
        var data = locations.length != 0
            ? locations.map(i => {
                if (i.type === 'CIRCLE' || i.type === 'MARKER' || i.type === 'POINT') {
                    i.position = i.position
                        ? {
                            ...i.position,
                            lat: i.position.lat || i.position.latitude || 0,
                            lng: i.position.lng || i.position.longitude || 0,
                        }
                        : {
                            lat: 0,
                            lng: 0,
                        };
                    if (Math.abs(i.position.lat) > 90) {
                        i.position.lat = 0;
                    };
                    if (Math.abs(i.position.lng) > 90) {
                        i.position.lat = 0;
                    };
                };
                return i;
            })
            : locations
        if (data.length != 0) {
            state.locations = data
        } else {
            Object.assign(state.locations, data);
        };

    },
    //#endregion
    //#region Operators
    addDriver(state, driver) {
        let index = state.drivers.map(i => i.id).indexOf(driver.id);
        if (index != -1) {
            state.drivers.splice(index, 1);
        };
        state.drivers.push(driver);
    },
    setDrivers(state, drivers) {
        Object.assign(state.drivers, drivers);
    },
    //#endregion
    //#region Assets
    setVehicles(state, vehicles) {
        Object.assign(state.vehicles, vehicles);
    },
    addVehicle(state, vehicle) {
        let index = state.vehicles.map(i => i.id).indexOf(vehicle.id);
        if (index != -1) {
            state.vehicles.splice(index, 1);
        };
        state.vehicles.push(vehicle);
    },
    updateVehicle(state, { changes, deviceId, driverId, vehicleId }) {
        let item = state.vehicles.filter(c => c.id == vehicleId).at(0);
        if (item == null) {
            return;
        };
        if (driverId) {
            item.driver = state.drivers.filter(c => c.id == driverId).at(0);
        };
        if (_.isNil(changes.address)) {
            changes.address = {};
        };
        if (_.isNil(changes.edgeCompute)) {
            item.edgeCompute = {};
        };

        if (changes.edgeCompute) {
            item.edgeCompute = changes.edgeCompute;
        }

        item.heading = changes.heading;
        item.latitude = changes.latitude;
        item.longitude = changes.longitude;
        item.locationDetail = changes.locationDetail;
        item.speed = changes.speed;
        item.vehicleStatus = changes.statusText;
        item.odometer = changes.runningODO;
        item.vehicleStatusValue = changes.singleStatus;

        if (item.lastPosition == undefined) { alert(1); }

        if (item.lastPosition.locationString == undefined) {
            item.lastPosition.locationString = {};
        };

        item.lastPosition.locationString.address = changes.address;
        item.lastPosition.bpStatusAttribute = changes.attributeTypeId;
        item.lastPosition.direction = changes.directionString;
        item.lastPosition.distance = changes.distance;
        item.lastPosition.lastKnownEventTime = changes.eventTime;
        item.lastPosition.heading = changes.heading;
        item.lastPosition.speed = changes.speed;
        item.lastPosition.runningOdometer = changes.runningODO;
        item.lastPosition.status = changes.singleStatus;
        item.lastPosition.statusText = changes.statusText;
        item.lastPosition.streetlimit = changes.address.streetlimit
        item.lastPosition.jobId = item.jobId ? item.jobId : "";
        item.lastPosition.jobStatus = item.jobStatus ? item.jobStatus : "";

        if (typeof liveMapLayers != 'undefined') {
            liveMapLayers.updateAsset(item);
        } else {
            console.error('Livemap Store', 'Unable to update asset, live map layer not in scope', item);
        }
    },
    //#endregion
    //#region Filters    
    updateFilter(state, { sort }) {
        ConfigurationService.updateSortingConfig("livemap-filter", sort);
    },
    //#endregion
    setTenant(state, tenantId) {
        state.tenantId = tenantId;
    },
    clearStore(state) {
        state.vehicles.splice(0);
        state.drivers.splice(0);
        state.locations.splice(0);
        createLogs && console.log("Store State", ...state.vehicles, ...state.drivers, ...state.locations);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
