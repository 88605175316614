<template>
    <v-card>
        <v-card-title v-if="showHeader" 
                      style="padding-bottom: 0px; display: flex; justify-content: space-between; padding-right: 8px;">
            {{$t('FleetLabels.commentHistory')}}
            <v-tooltip bottom v-if="filterResults.length != 0">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon
                           v-bind="attrs"
                           v-on="on"
                           @click.stop="exportComments">
                        <v-icon>mdi-export-variant</v-icon>
                    </v-btn>
                </template>
                <span>{{$t("DriverLabels.exportToexcel")}}</span>
            </v-tooltip>
        </v-card-title>
        <v-card-text :class="[dense ? 'denseCardText' : '']">
            <v-text-field v-if="showHeader"
                          v-model="search"
                          append-icon="mdi-magnify"
                          :label="$t('GroupLable.search')"
                          single-line clearable
                          hide-details
                          style="padding-top: 0;">
            </v-text-field>
            <v-list :class="[scrollOnOverflow ? 'overflow' : '']">
                <v-list-item v-for="(comment,index) in filterResults"
                             :key="index"
                             dense>
                    <v-list-item-content>
                        <v-list-item-subtitle class="splitDisplay" v-if="comment.createdBy || comment.commentDateTime">
                            <span v-if="comment.createdBy">{{comment.createdBy}}</span>
                            <span v-if="comment.commentDateTime">{{dateDisplay(comment.commentDateTime)}}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <v-alert text shaped
                                     class="messageBlock">
                                <strong style="display: block; white-space: pre-wrap;">{{ comment.comment }}</strong>
                            </v-alert>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="filterResults.length == 0">
                    <v-icon style="padding-right:8px;">mdi-tooltip-question-outline</v-icon> {{$t('FleetLabels.noCommentsFound')}}
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>
<script>
    import { saveExcel } from '@progress/kendo-vue-excel-export';
    export default {
        props: {
            alertItem: {
                type: Object,
                default() {
                    return {
                        actionComments: [],
                    }
                },
            },
            showHeader: {
                type: Boolean,
                default() {
                    return false;
                },
            },
            scrollOnOverflow: {
                type: Boolean,
                default() {
                    return false;
                },
            },
            dense: {
                type: Boolean,
                default() {
                    return false;
                },
            },
        },
        mounted() {
            let requiredUserList = this.alertItem?.actionComments?.map(i => i.createdBy).filter(i => i) || []
            if (requiredUserList.length > 0) {
                let knownUserList = this.getUsersData.map(i => i.id);
                let missingUsers = requiredUserList.filter(i => !knownUserList.includes(i));
                if (missingUsers.length > 0) {
                    if (this.getAllUsersData.length == 0) {
                        this.getAllUsers();
                    };
                };
            };
        },
        data() {
            return {
                search: '',
            }
        },
        methods: {
            getAllUsers() {
                this.$userStore.getAllUsers();
            },
            exportComments() {
                saveExcel({
                    data: this.filterResults.map(i => ({ ...i, commentDateTime: this.dateDisplay(i.commentDateTime)})),
                    fileName: "export",
                    columns: [
                        { field: 'commentDateTime', title: this.$t("FleetLabels.commentDateTime") },
                        { field: 'createdBy', title: this.$t('FleetLabels.commentedBy') },
                        { field: 'comment', title: this.$t('jobList.changeState.comment') }
                    ],
                    avoidLinks: true
                });
            },
            dateDisplay(commentDateTime) {
                return this.$formatUTCDateTime(commentDateTime * 1000);
            },
        },
        computed: {
            getAllUsersData() {
                return this.$userStore.getAllUsersData();
            },
            getUsersData() {
                return this.$userStore.getUsersData();
            },
            commentHistory() {
                let commentList = this.alertItem && this.alertItem.actionComments
                    ? this.alertItem.actionComments
                    : [];
                return [...commentList
                    .sort((a, b) => {
                        return a.commentDateTime < b.commentDateTime ? 1 : -1;
                    })
                    .map(commentItem => {
                        let user = this.getUsersData.find(user => user.id == commentItem.createdBy)
                            || this.getAllUsersData.find(user => user.id == commentItem.createdBy)
                        commentItem.createdBy = user
                            ? user.displayName
                            : commentItem.createdBy || null;
                        return commentItem;
                    })];
            },
            filterResults() {
                return this.search
                    ? this.commentHistory.filter(i => i.createdBy.toLowerCase().includes(this.search.toLowerCase())
                        || i.comment.toLowerCase().includes(this.search.toLowerCase()))
                    : this.commentHistory
            },
        },
    }
</script>
<style scoped>
    .splitDisplay {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .messageBlock {
        color: rgb(0, 43, 73) !important;
        border-radius: 0px 16px 16px 16px !important;
        margin-bottom: 0px;
    }

    .v-list {
        background-color: transparent;
    }

    .denseCardText {
        padding-top: 0;
        padding-bottom: 0;
    }

    .overflow {
        max-height: 330px;
        overflow-y: auto;
    }

    .v-list-item {
        border-bottom: solid thin #40c1ac;
        padding-left: 4px;
    }
</style>