import { i18n } from '@/./shared/i18n.js';
import { defineStore } from 'pinia';
import configurationService from '@/services/configuration.service';
import importGeozoneDropdownCell from '../components/grids/custom-cells/import-geozone-dropdown-cell';
import importLocationTypeCell from '../components/grids/custom-cells/import-location-type-cell';
import importValidationCell from '../components/grids/custom-cells/import-validation-cell';

export const useColumnStore = defineStore('column', {
    state: () => ({
        columns: [],
        crystalImportLocationHeaders: [
            { title: 'Valid', field: 'valid', cell: importValidationCell, hidden: false, editable: false, filter: 'text', width: 200 },
            { title: 'Type', field: 'location.type', cell: importLocationTypeCell, editor: 'editLocation', hidden: false, filter: 'text', width: 250 },
            { title: 'Name', field: 'location.name', hidden: false, filter: 'text', width: 350 },
            { title: 'Geofence Type', field: 'location.geozone', cell: importGeozoneDropdownCell, hidden: false, filter: 'text', width: 300 },
            { title: 'Location Type', field: 'location.tag', hidden: false, filter: 'text', width: 250 },
            { title: 'Category', field: 'location.category', hidden: false, filter: 'text', width: 250 },
            { title: 'Location Address', field: 'location.address', hidden: false, filter: 'text', width: 350 },
            { title: 'Location Description', field: 'location.description', hidden: false, filter: 'text', width: 250 },
            { title: 'Radius Meter', field: 'location.radius', hidden: false, filter: 'text', width: 200 },
            { title: 'Tolerance Meters', field: 'location.tolerance', hidden: false, filter: 'text', width: 200 },
            { title: 'First Name', field: 'contact.firstName', hidden: false, filter: 'text', width: 250 },
            { title: 'Last Name', field: 'contact.lastName', hidden: false, filter: 'text', width: 250 },
            { title: 'Phone', field: 'contact.mobilePhone', hidden: false, filter: 'text', width: 250 },
            { title: 'Email', field: 'contact.emailAddresses', hidden: false, filter: 'text', width: 250 },
            { title: 'Contact Description', field: 'contact.description', hidden: false, filter: 'text', width: 250 },
            { title: 'Company', field: 'contact.company', hidden: false, filter: 'text', width: 250 },
            { title: 'Street', field: 'contact.businessAddress.street', hidden: false, filter: 'text', width: 250 },
            { title: 'City', field: 'contact.businessAddress.city', hidden: false, filter: 'text', width: 250 },
            { title: 'State', field: 'contact.businessAddress.state', hidden: false, filter: 'text', width: 250 },
            { title: 'Country', field: 'contact.businessAddress.countryOrRegion', hidden: false, filter: 'text', width: 250 },
            { title: 'Zip', field: 'contact.businessAddress.postalCode', hidden: false, filter: 'text', width: 250 },
        ],
        columnGroups: {
            vehicles: [
                {
                    name: 'Dashboard.assetStatus',
                    icon: 'monitor_heart',
                    columns: ['selected','entityWarnings', 'idFormat', 'vehicleStatus', 'vehicleName', 'registrationNumber', 'assignedDriver', 'operationalStatus', 'odometer', 'runningHours','alertPolicies','tagEnabledTXT'],
                },
                {
                    name: 'VehicleLabels.vehicleDetails',
                    icon: 'feed',
                    columns: ['selected', 'idFormat', 'vehicleStatus', 'vehicleName', 'registrationNumber', 'description', 'fleetNumber', 'year', 'make', 'model', 'color', 'VIN', 'serialNumber', 'fuelType', 'CO2Emission', 'traceCellNo', 'tachoEnabled','vehicleSkillSet','tagEnabledTXT'],
                },
                {
                    name: 'VehicleLabels.vehicleLocation',
                    icon: 'pin_drop',
                    columns: ['selected', 'idFormat', 'vehicleStatus', 'vehicleName', 'registrationNumber', 'driverId', 'speedLimitOrder', 'heading', 'location', 'address', 'longitude', 'latitude', 'cameras'],
                },
                {
                    name: 'VehicleMaintenanceLabels.ODO',
                    icon: 'speed',
                    columns: ['selected', 'idFormat', 'vehicleStatus', 'vehicleName', 'registrationNumber', 'odometer', 'runningHours', 'totalDriveTime', 'totalStopTime', 'firstStartUpTime', 'lastReportedTime'],
                },
            ],
            drivers: [
                {
                    name: 'LocationLabels.contactInfo',
                    icon: 'perm_phone_msg',
                    columns: ['selected', 'displayName', 'driverId', 'mobile', 'emailId', 'homeLocation', 'emergencyRelationship', 'emergencyName', 'emergencyContact', 'emergencyEmail', 'workLocation'],
                },
                {
                    name: 'DriverLabels.driverTag',
                    icon: 'mdi-tag',
                    columns: ['selected', 'displayName', 'driverId', 'currentVehicle', 'driverTag', 'driverTagExpiry', 'driverTagCategories','assignedVehicle']
                },
                {
                    name: 'DriverLabels.driverDetails',
                    icon: 'feed',
                    columns: ['selected', 'displayName', 'description', 'driverId', 'currentVehicle', 'driverTag', 'assignedVehicle', 'skillsSet', 'identificationNumber', 'driverAllowedNotification', 'mobile', 'emailId']
                },
            ],
            videos: [
                {
                    name: 'Videos.information',
                    icon: 'feed',
                    columns: ['selected', 'displayName', 'identifier', 'deviceId', 'cameraId'],
                },
                {
                    name: 'Videos.videoRequest',
                    icon: 'videocam',
                    columns: ['selected', 'displayName', 'statusText', 'eventDateUTC', 'requestDateUTC', 'videoStatuses'],
                },
            ],
            ServicePlans: [
                {
                    name: 'ServicePlan.progress',
                    icon: 'rotate_right',
                    columns: ['selected', 'servicePlanName', 'vehicleDisplayName', 'serviceType', 'status', 'lastServiceDetails', 'distanceTravelled', 'nextDueDateDisplay'],
                },
                {
                    name: 'ServicePlan.planning',
                    icon: 'calendar_month',
                    columns: ['selected', 'servicePlanName', 'vehicleDisplayName', 'serviceType', 'status', 'nextDueInDate'],
                },
            ],
            serviceHistory: [
                {
                    name: 'ServicePlan.previous',
                    icon: 'history_toggle_off',
                    columns: ['selected', 'vehicleDisplayName', 'servicePlanName', 'serviceType', 'status', 'previousServiceDateUtc', 'previousServiceCompletedBy', 'previousServiceCompletedDateTimeUtc', 'previousRunningHours', 'previousServiceOdometer'],
                },
                {
                    name: 'ServicePlan.completed',
                    icon: 'done',
                    columns: ['selected', 'vehicleDisplayName', 'servicePlanName', 'serviceType', 'status', 'completedBy', 'completedDateTimeUtc', 'runningHours', 'serviceODO'],
                },
                {
                    name: 'ServicePlan.notifications',
                    icon: 'notifications_active',
                    columns: ['selected', 'vehicleDisplayName', 'servicePlanName', 'serviceType', 'status', 'latestNotificationDateTimeUtc'],
                },
                {
                    name: 'ServicePlan.dates',
                    icon: 'event_available',
                    columns: ['selected', 'vehicleDisplayName', 'servicePlanName', 'serviceType', 'status', 'completedDateTimeUtc', 'previousServiceDateUtc'],
                },
                {
                    name: 'ServicePlan.odoComparison',
                    icon: 'speed',
                    columns: ['selected', 'vehicleDisplayName', 'servicePlanName', 'serviceType', 'status', 'completedDateTimeUtc', 'serviceODO', 'previousServiceDateUtc', 'previousServiceOdometer'],
                },
                {
                    name: 'ServicePlan.runningHoursComparison',
                    icon: 'timer',
                    columns: ['selected', 'vehicleDisplayName', 'servicePlanName', 'serviceType', 'status', 'completedDateTimeUtc', 'runningHours', 'previousServiceDateUtc', 'previousRunningHours'],
                },
            ],
            LicensingLists: [
                {
                    name: 'VehicleMaintenanceLabels.renewals',
                    icon: 'autorenew',
                    columns: ['selected', 'vehicleDisplayName', 'licenseType', 'documentNo', 'renewedDate', 'renewablePeriod'],
                },
                {
                    name: 'VehicleMaintenanceLabels.reminders',
                    icon: 'notifications_active',
                    columns: ['selected', 'vehicleDisplayName', 'licenseType', 'documentNo', 'LicensingStatus', 'nextDueDate', 'reminderStart'],
                },
            ],
            InspectionLists: [
                {
                    name: 'VehicleMaintenanceLabels.operatorPerspective',
                    icon: 'person',
                    columns: ['selected', 'templateName', 'driverDisplayName', 'submissionDateTimeUTC', 'submissionTime', 'location', 'good', 'issue', 'critical'],
                },
                {
                    name: 'VehicleMaintenanceLabels.assetPerspective',
                    icon: 'directions_car',
                    columns: ['selected', 'templateName', 'defaultVehicleId', 'submissionDateTimeUTC', 'submissionTime', 'location', 'good', 'issue', 'critical'],
                },
            ],
            JobListFiltered: [
                {
                    name: 'jobList.search.details',
                    icon: 'feed',
                    columns: ['selected', 'jobList_isRecurring', 'status', 'name', 'customer', 'docReference', 'description', 'locationName', 'jobDate', 'stayTimeMinutes', 'preferredFromTimeUTC', 'preferredToTimeUTC', 'jobList_icon_reqSign', 'jobList_icon_reqPhoto', 'requiredSkill', 'driver', 'vehicle'],
                },
                {
                    name: 'addjob.assignments',
                    icon: 'assignment_ind',
                    columns: ['selected', 'jobList_isRecurring', 'jobList-isRecurring', 'status', 'name', 'customer', 'docReference', 'locationName', 'jobDate', 'stayTimeMinutes', 'preferredFromTimeUTC', 'preferredToTimeUTC', 'driver', 'vehicle'],
                },
                {
                    name: 'addjob.epodRequirements',
                    icon: 'edit_note',
                    columns: ['selected', 'jobList_isRecurring', 'status', 'name', 'customer', 'docReference', 'locationName', 'jobList_icon_reqSign', 'jobList_icon_reqPhoto', 'completionDetails', 'rejectionDetails', 'attachments'],
                },
                {
                    name: 'jobList.completionDetails',
                    icon: 'done',
                    columns: ['selected', 'jobList_isRecurring', 'status', 'name', 'customer', 'docReference', 'locationName', 'jobDate', 'rejectionDetails', 'completionDetails', 'actualArrivalUTC', 'actualStayTimeMinutes', 'distanceCoveredMeter', 'jobList_icon_reqSign', 'jobList_icon_reqPhoto', 'attachments', 'stayTimeMinutes','lastCompletedDateUTC', 'driver', 'vehicle'],
                },
            ],
            locations: [
                {
                    name: 'Location.contactDetails',
                    icon: 'perm_phone_msg',
                    columns: ['selected', 'name', 'description', 'address', 'contactname', 'contactemail', 'contactnumber'],
                },
                {
                    name: 'LocationLabels.category',
                    icon: 'mdi-tag',
                    columns: ['selected', 'name', 'description', 'address', 'geozone', 'category', 'tolerance'],
                },
                {
                    name: 'Location.locationDetails',
                    icon: 'location_on',
                    columns: ['selected', 'name', 'address', 'geozone', 'category', 'tolerance', 'tag', 'longitude', 'latitude'],
                },
            ],
            users: [
                {
                    name: 'Location.contactDetails',
                    icon: 'perm_phone_msg',
                    columns: ['selected', 'description', 'displayName', 'mobile', 'emailId', 'driverAllowedNotification', 'workLocation'],
                },
                {
                    name: 'UserLabels.role',
                    icon: 'feed',
                    columns: ['selected', 'description', 'displayName', 'rolesAssigned', 'emailId'],
                },
            ],
            contacts: [
                {
                    name: 'VehicleLabels.address',
                    icon: 'location_on',
                    columns: ['selected', 'description', 'firstName', 'lastName', 'jobTitle', 'company', 'street', 'city', 'state', 'countryOrRegion', 'postalCode', 'mobilePhone', 'emailAddresses'],
                },
                {
                    name: 'Location.contactDetails',
                    icon: 'perm_phone_msg',
                    columns: ['selected', 'description', 'firstName', 'lastName', 'company', 'jobTitle', 'city', 'mobilePhone', 'emailAddresses'],
                },
            ],
            vendors: [
                {
                    name: 'quickFilter.vendor.ByContactDetails',
                    icon: 'perm_phone_msg',
                    columns: ['selected', 'name', 'vendorCity', 'contactName', 'contactEmail', 'contactNumber', 'vendorAddress'],
                },
                {
                    name: 'quickFilter.vendor.ByAccountDetails',
                    icon: 'mdi-card-account-details-outline',
                    columns: ['selected', 'name', 'vendorCity', 'contactName', 'contactEmail', 'contactNumber', 'vatNumber', 'accountNumber'],
                },
            ],
            //#region AMM
            assetLifeCyclesListed: [
                {
                    name: 'amm.lifeCycle.highLevel',
                    icon: 'mdi-car-brake-retarder',
                    columns: ['selected', 'commands', 'displayName', 'assetTypeName', 'lifeCycleManagement', 'costPerDay', 'costPerKm','costPerHour'],
                },
                {
                    name: 'amm.asset.purchase.Details',
                    icon: 'mdi-train-car-flatbed-car',
                    columns: ['selected', 'commands', 'displayName', 'lifeCycleManagement', 'purchaseDate', 'purchaseAmount','financing'],
                },
                {
                    name: 'amm.asset.activation.details',
                    icon: 'mdi-car-key',
                    columns: ['selected', 'commands', 'displayName', 'lifeCycleManagement', 'activationDate','activationODO'],
                },
                {
                    name: 'amm.asset.warranty.details',
                    icon: 'mdi-car-wrench',
                    columns: ['selected', 'commands', 'displayName', 'lifeCycleManagement', 'maxOdoAllowed','expirationDate'],
                },
                {
                    name: 'amm.asset.lifeEstimate.details',
                    icon: 'mdi-car-speed-limiter',
                    columns: ['selected', 'commands', 'displayName', 'lifeCycleManagement', 'inMonths', 'ODO','avgWorkingHoursPerDay'],
                },
                {
                    name: 'amm.asset.depreciation',
                    icon: 'trending_down',
                    columns: ['selected', 'commands', 'displayName', 'lifeCycleManagement', 'depreciationStrategy','annualDepreciationValue'],
                },
                {
                    name: 'amm.asset.retirement',
                    icon: 'mdi-car-off',
                    columns: ['selected', 'commands', 'displayName', 'lifeCycleManagement', 'retirementDate', 'retirementODO','saleValue'],
                },
            ],
            assetsTransactionalHistory: [
                {
                    name: 'quickFilter.transactionHistory.vendorFocused',
                    icon: 'mdi-store',
                    columns: ['selected', 'commands', 'assetDisplayName', 'date', 'type', 'amount','vendorName', 'recurranceDisplay',],
                },
                {
                    name: 'quickFilter.transactionHistory.transactionCategoryFocused',
                    icon: 'mdi-format-list-group',
                    columns: ['selected', 'commands', 'assetDisplayName', 'date', 'type', 'amount', 'category', 'subCategory','recurranceDisplay'],
                },
                {
                    name: 'quickFilter.transactionHistory.documentsFocused',
                    icon: 'mdi-file-document-outline',
                    columns: ['selected', 'commands', 'assetDisplayName', 'date', 'type', 'amount', 'notes','attachments','recurranceDisplay'],
                },
            ],
            operatorsTransactionalHistory: [
                {
                    name: 'quickFilter.transactionHistory.vendorFocused',
                    icon: 'mdi-store',
                    columns: ['selected', 'commands', 'operatorDisplayName', 'date', 'type', 'amount', 'vendorName', 'recurranceDisplay',],
                },
                {
                    name: 'quickFilter.transactionHistory.transactionCategoryFocused',
                    icon: 'mdi-format-list-group',
                    columns: ['selected', 'commands', 'operatorDisplayName', 'date', 'type', 'amount', 'category', 'subCategory', 'recurranceDisplay'],
                },
                {
                    name: 'quickFilter.transactionHistory.documentsFocused',
                    icon: 'mdi-file-document-outline',
                    columns: ['selected', 'commands', 'operatorDisplayName', 'date', 'type', 'amount', 'notes', 'attachments', 'recurranceDisplay'],
                },
            ],
            transactionalHistory: [
                {
                    name: 'quickFilter.transactionHistory.vendorFocused',
                    icon: 'mdi-store',
                    columns: ['selected', 'commands', 'assetDisplayName','operatorDisplayName', 'date', 'type', 'amount', 'vendorName', 'recurranceDisplay',],
                },
                {
                    name: 'quickFilter.transactionHistory.transactionCategoryFocused',
                    icon: 'mdi-format-list-group',
                    columns: ['selected', 'commands', 'assetDisplayName', 'operatorDisplayName', 'date', 'type', 'amount', 'category', 'subCategory', 'recurranceDisplay'],
                },
                {
                    name: 'quickFilter.transactionHistory.documentsFocused',
                    icon: 'mdi-file-document-outline',
                    columns: ['selected', 'commands', 'assetDisplayName', 'operatorDisplayName', 'date', 'type', 'amount', 'notes', 'attachments', 'recurranceDisplay'],
                },
            ],
            operatorDetailedTransactionalHistory: [
                {
                    name: 'quickFilter.transactionHistory.vendorFocused',
                    icon: 'mdi-store',
                    columns: ['selected', 'commands', 'date', 'type', 'amount', 'vendorName', 'recurranceDisplay',],
                },
                {
                    name: 'quickFilter.transactionHistory.transactionCategoryFocused',
                    icon: 'mdi-format-list-group',
                    columns: ['selected', 'commands',  'date', 'type', 'amount', 'category', 'subCategory', 'recurranceDisplay'],
                },
                {
                    name: 'quickFilter.transactionHistory.documentsFocused',
                    icon: 'mdi-file-document-outline',
                    columns: ['selected', 'commands',  'date', 'type', 'amount', 'notes', 'attachments', 'recurranceDisplay'],
                },
            ],
            assetDetailedTransactionalHistory: [
                {
                    name: 'quickFilter.transactionHistory.vendorFocused',
                    icon: 'mdi-store',
                    columns: ['selected', 'commands', 'date', 'type', 'amount', 'vendorName', 'recurranceDisplay',],
                },
                {
                    name: 'quickFilter.transactionHistory.transactionCategoryFocused',
                    icon: 'mdi-format-list-group',
                    columns: ['selected', 'commands', 'date', 'type', 'amount', 'category', 'subCategory', 'recurranceDisplay'],
                },
                {
                    name: 'quickFilter.transactionHistory.documentsFocused',
                    icon: 'mdi-file-document-outline',
                    columns: ['selected', 'commands', 'date', 'type', 'amount', 'notes', 'attachments', 'recurranceDisplay'],
                },
            ],
            //#endregion
        },
        quickFilter: { //and / or ;  eq, neq, isnull, isnotnull, lt, lte, gt, gte, startswith, doesnotstartwith, endswith, doesnotendwith, contains, doesnotcontain, isempty, isnotempty
            vehicles: [
                {
                    title: "quickFilter.vehicles.hasTacho",
                    filter: {
                        logic: 'and',
                        filters: [
                            {
                                field: 'tachoEnabled',
                                operator: 'eq',
                                value: true,
                            }
                        ],
                    },
                    columns: {
                        required: [
                            'tachoEnabled', 'idFormat', 'vehicleStatus',
                        ],
                    },
                }, //hasTacho
                {
                    title: "quickFilter.vehicles.hasVideo",
                    filter: {
                        logic: 'and',
                        filters: [
                            {
                                field: 'cameras',
                                operator: function (data) {
                                    return data.length != 0
                                },
                            }
                        ],
                    },
                    columns: {
                        required: [
                            'cameras', 'idFormat', 'vehicleStatus',
                        ],
                    },
                }, //hasVideo
                {
                    title: "quickFilter.vehicles.hasWarnings",
                    filter: {
                        logic: 'and',
                        filters: [
                            {
                                field: 'entityWarnings',
                                operator: function (data) {
                                    return data?.toolTip ? true : false
                                },
                            },
                        ],
                    },
                    columns: {
                        required: [
                            'entityWarnings', 'idFormat', 'vehicleStatus',
                        ],
                    },
                }, //hasWarnings
                {
                    title: "quickFilter.vehicles.byFuelType",
                    groupBy: 'fuelType',
                    dataType: 'String',
                    columns: {
                        required: [
                            'fuelType', 'idFormat', 'vehicleStatus',
                        ],
                    },
                }, //byFuelType
                {
                    title: "quickFilter.vehicles.byCurrentStatus",
                    groupBy: 'vehicleStatus',
                    dataType: 'String',
                    columns: {
                        required: [
                            'idFormat', 'vehicleStatus',
                        ],
                    },
                }, //byCurrentStatus
                {
                    title: "quickFilter.vehicles.byMakeModelYear",
                    groupName: 'byMakeModelYear',
                    groupBy: {
                        value: 'make',
                        dataType: 'String',
                        groupBy: {
                            value: 'model',
                            dataType: 'String',
                            groupBy: {
                                value: 'year',
                                dataType: 'String',
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'idFormat', 'vehicleStatus', 'model', 'make', 'year',
                        ],
                    },
                }, //byMakeModelYear
                {
                    title: "quickFilter.vehicles.byAlertPolicies",
                    groupName: 'assetsByalertPolicies',
                    groupBy: {
                        value: 'alertPolicies',
                        dataType: 'Custom',
                        valueConversion: function (value) {
                            return value?.list?.map(i => i.item) || [];
                        },
                        groupBy: {
                            value: 'alertPolicies',
                            dataType: 'Custom',
                            valueConversion: function (value) {
                                return value?.list?.map(i => i.child) || [];
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'entityWarnings', 'idFormat', 'vehicleStatus',
                        ],
                    },
                }, //byAlertPolicies
                {
                    title: "VehicleLabels.tagEnabled",
                    groupBy: 'tagEnabledTXT',
                    dataType: 'String',
                    columns: {
                        required: [
                            'tagEnabledTXT', 'idFormat', 'vehicleStatus',
                        ],
                    },
                    processBlanks: true,
                }, //tagEnabled
            ],
            drivers: [
                {
                    title: "quickFilter.drivers.hasTag",
                    filter: {
                        logic: 'and',
                        filters: [
                            {
                                field: 'driverTag',
                                operator: 'isnotempty',
                            }
                        ],
                    },
                    columns: {
                        required: [
                            'displayName', 'driverId', 'driverTag', 'driverTagExpiry','driverTagCategories',
                        ],
                    },
                }, //hasTag
                {
                    title: "quickFilter.drivers.hasNoTag",
                    filter: {
                        logic: 'and',
                        filters: [
                            {
                                field: 'driverTag',
                                operator: 'isempty',
                            }
                        ],
                    },
                    columns: {
                        required: [
                            'displayName', 'driverId', 'driverTag', 'driverTagExpiry','driverTagCategories',
                        ],
                    },
                }, //hasNoTag
                {
                    title: "quickFilter.drivers.physicalTag",
                    filter: {
                        logic: 'and',
                        filters: [
                            {
                                field: 'driverTagExpiry',
                                operator: function (data) {
                                    return data == i18n.t('DriverLabels.expiryDateUnManaged')
                                },
                            }
                        ],
                    },
                    columns: {
                        required: [
                            'displayName', 'driverId', 'driverTag', 'driverTagExpiry','driverTagCategories',
                        ],
                    },
                }, //physicalTag
                {
                    title: "quickFilter.drivers.byTagCategory",
                    groupBy: 'driverTagCategories',
                    dataType: 'CSV',
                    columns: {
                        required: [
                             'displayName', 'driverId', 'driverTag', 'driverTagExpiry','driverTagCategories',
                        ],
                    },
                }, //byTagCategory
                {
                    title: "quickFilter.drivers.bySkillset",
                    groupBy: 'skillsSet',
                    dataType: 'CSV',
                    columns: {
                        required: [
                            'displayName', 'driverId', 'skillsSet',
                        ],
                    },
                }, //bySkillset
                {
                    title: "quickFilter.drivers.byNotificationMethod",
                    groupBy: 'driverAllowedNotification',
                    dataType: 'CSV',
                    columns: {
                        required: [
                            'displayName', 'driverId', 'driverAllowedNotification',
                        ],
                    },
                }, //byNotificationMethod
                {
                    title: "quickFilter.drivers.byWorkLocation",
                    groupBy: 'workLocation',
                    dataType: 'String',
                    columns: {
                        required: [
                            'displayName', 'driverId', 'workLocation',
                        ],
                    },
                }, //byWorkLocation
                {
                    title: "quickFilter.drivers.byAssignedAsset",
                    groupName: 'assignedVehicleGroup',
                    groupBy: {
                        value: 'assignedVehicle',
                        dataType: 'Custom',
                        valueConversion: function (value) {
                            return value
                                ? i18n.t('RouteLabels.assignFavourites.assigned')
                                : i18n.t('RouteLabels.assignFavourites.unassigned');
                        },
                        groupBy: {
                            value: 'assignedVehicle',
                            dataType: 'CSV',
                        },
                    },
                    processBlanks: true,
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'displayName', 'driverId', 'driverTag', 'assignedVehicle',
                        ],
                    },
                }, //byAssignedAsset
            ],
            videos: [
                {
                    title: "quickFilter.videos.byStatusText",
                    groupName: 'byStatusText',
                    groupBy: {
                        value: 'statusText',
                        dataType: 'String',
                        groupBy: {
                            value: 'videoStatuses',
                            dataType: 'Array',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'displayName', 'statusText', 'eventDateUTC', 'requestDateUTC', 'videoStatuses'
                        ],
                    },
                }, //byStatusText
                {
                    title: "quickFilter.videos.byVideoStatuses",
                    groupName: 'byVideoStatuses',
                    groupBy: {
                        value: 'videoStatuses',
                        dataType: 'Array',
                        groupBy: {
                            value: 'statusText',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'displayName', 'statusText', 'eventDateUTC', 'requestDateUTC', 'videoStatuses'
                        ],
                    },
                }, //byVideoStatuses
                {
                    title: "quickFilter.videos.byEventDateUTC",
                    groupBy: 'eventDateUTC',
                    dataType: 'Date',
                    columns: {
                        required: [
                            'displayName', 'statusText', 'eventDateUTC', 'requestDateUTC', 'videoStatuses'
                        ],
                    },
                }, //byEventDateUTC
            ],
            ServicePlans: [
                {
                    title: "quickFilter.servicePlans.byServiceType",
                    groupName: 'ServicePlansbyServiceType',
                    groupBy: {
                        value: 'serviceType',
                        dataType: 'String',
                        groupBy: {
                            value: 'status',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'servicePlanName', 'vehicleDisplayName', 'serviceType', 'status', 'lastServiceDetails', 'distanceTravelled', 'nextDueDateDisplay',
                        ],
                    },
                }, //byServiceType
                {
                    title: "quickFilter.servicePlans.byStatus",
                    groupName: 'ServicePlansbyStatus',
                    groupBy: {
                        value: 'status',
                        dataType: 'String',
                        groupBy: {
                            value: 'serviceType',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'servicePlanName', 'vehicleDisplayName', 'serviceType', 'status', 'lastServiceDetails', 'distanceTravelled', 'nextDueDateDisplay',
                        ],
                    },
                }, //byStatus
                {
                    title: "quickFilter.servicePlans.byLastServiced",
                    groupBy: 'lastServiceDateOnly',
                    dataType: 'Date',
                    columns: {
                        required: [
                            'servicePlanName', 'vehicleDisplayName', 'serviceType', 'status', 'lastServiceDetails', 'distanceTravelled', 'nextDueDateDisplay',
                        ],
                    },
                }, //byLastServiced
                {
                    title: "quickFilter.servicePlans.byNextServiced",
                    groupBy: 'nextDueDate',
                    dataType: 'Date',
                    columns: {
                        required: [
                            'servicePlanName', 'vehicleDisplayName', 'serviceType', 'status', 'lastServiceDetails', 'distanceTravelled', 'nextDueDateDisplay',
                        ],
                    },
                }, //byNextServiced
            ],
            serviceHistory: [
                {
                    title: "quickFilter.servicePlans.byServiceType",
                    groupName: 'serviceHistorybyServiceType',
                    groupBy: {
                        value: 'serviceType',
                        dataType: 'String',
                        groupBy: {
                            value: 'status',
                            dataType: 'String',
                        },
                    },

                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'vehicleDisplayName', 'servicePlanName', 'serviceType', 'status',
                        ],
                    },
                }, //byServiceType
                {
                    title: "quickFilter.servicePlans.byStatus",
                    groupName: 'serviceHistorybyStatus',
                    groupBy: {
                        value: 'status',
                        dataType: 'String',
                        groupBy: {
                            value: 'serviceType',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'vehicleDisplayName', 'servicePlanName', 'serviceType', 'status',
                        ],
                    },
                }, //byStatus
                {
                    title: "quickFilter.servicePlans.byPreviousService",
                    groupBy: 'previousServiceDateUtc',
                    dataType: 'Date',
                    columns: {
                        required: [
                            'vehicleDisplayName', 'servicePlanName', 'serviceType', 'status', 'previousServiceDateUtc', 'previousRunningHours', 'previousServiceOdometer',
                        ],
                    },
                }, //byPreviousService
                {
                    title: "quickFilter.servicePlans.byCompletedDate",
                    groupBy: 'completedDateTimeUtc',
                    dataType: 'Date',
                    columns: {
                        required: [
                            'vehicleDisplayName', 'servicePlanName', 'serviceType', 'status', 'completedBy', 'completedDateTimeUtc', 'runningHours', 'serviceODO',
                        ],
                    },
                }, //byCompletedDate
            ],
            LicensingLists: [
                {
                    title: "quickFilter.license.byType",
                    groupName: 'LicensingListsbyType',
                    groupBy: {
                        value: 'licenseType',
                        dataType: 'String',
                        groupBy: {
                            value: 'LicensingStatus',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'vehicleDisplayName', 'licenseType', 'documentNo',
                        ],
                    },
                }, //byType
                {
                    title: "quickFilter.license.byStatus",
                    groupName: 'LicensingListsbyStatus',
                    groupBy: {
                        value: 'LicensingStatus',
                        dataType: 'String',
                        groupBy: {
                            value: 'licenseType',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'vehicleDisplayName', 'licenseType', 'documentNo', 'LicensingStatus', 'nextDueDate',
                        ],
                    },
                }, //byStatus
                {
                    title: "quickFilter.license.byValidityPeriod",
                    groupBy: 'renewablePeriod',
                    dataType: 'String',
                    columns: {
                        required: [
                            'vehicleDisplayName', 'licenseType', 'documentNo', 'renewablePeriod',
                        ],
                    },
                }, //byValidityPeriod
                {
                    title: "quickFilter.license.byRenewedDate",
                    groupBy: 'renewedDate',
                    dataType: 'Date',
                    columns: {
                        required: [
                            'vehicleDisplayName', 'licenseType', 'documentNo', 'renewedDate',
                        ],
                    },
                }, //byRenewedDate
                {
                    title: "quickFilter.license.byDueDate",
                    groupBy: 'nextDueDate',
                    dataType: 'Date',
                    columns: {
                        required: [
                            'vehicleDisplayName', 'licenseType', 'documentNo', 'nextDueDate',
                        ],
                    },
                }, //byDueDate
            ],
            InspectionLists: [
                {
                    title: "quickFilter.inspectionLists.byTemplate",
                    groupName: 'InspectionListsbyTemplate',
                    groupBy: {
                        value: 'templateName',
                        dataType: 'String',
                        groupBy: {
                            value: 'submissionDateTimeUTC',
                            dataType: 'Date',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'templateName', 'submissionDateTimeUTC', 'good', 'issue', 'critical',
                        ],
                    },
                }, //byTemplate
                {
                    title: "quickFilter.inspectionLists.byDate",
                    groupName: 'InspectionListsbyDate',
                    groupBy: {
                        value: 'submissionDateTimeUTC',
                        dataType: 'Date',
                        groupBy: {
                            value: 'templateName',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'templateName', 'submissionDateTimeUTC', 'good', 'issue', 'critical',
                        ],
                    },
                }, //byDate
                {
                    title: "quickFilter.inspectionLists.byGoodScore",
                    groupName: 'InspectionListsbyGoodScore',
                    groupBy: {
                        value: 'good',
                        dataType: 'String',
                        groupBy: {
                            value: 'templateName',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'templateName', 'submissionDateTimeUTC', 'good', 'issue', 'critical',
                        ],
                    },
                }, //byGoodScore
                {
                    title: "quickFilter.inspectionLists.byIssueScore",
                    groupName: 'InspectionListsbyIssueScore',
                    groupBy: {
                        value: 'issue',
                        dataType: 'String',
                        groupBy: {
                            value: 'templateName',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'templateName', 'submissionDateTimeUTC', 'good', 'issue', 'critical',
                        ],
                    },
                }, //byIssueScore
                {
                    title: "quickFilter.inspectionLists.byCriticalScore",
                    groupName: 'InspectionListsbyCriticalScore',
                    groupBy: {
                        value: 'critical',
                        dataType: 'String',
                        groupBy: {
                            value: 'templateName',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'templateName', 'submissionDateTimeUTC', 'good', 'issue', 'critical',
                        ],
                    },
                }, //byCriticalScore
            ],
            JobListFiltered: [
                {
                    title: "quickFilter.jobs.byStatus",
                    groupBy: 'status',
                    dataType: 'String',
                    columns: {
                        required: [
                            'jobDate', 'status', 'name', 'customer', 'docReference', 'locationName',
                        ],
                    },
                }, //byStatus
                {
                    title: "quickFilter.jobs.byDate",
                    groupBy: 'jobDate',
                    dataType: 'String',
                    columns: {
                        required: [
                            'jobDate', 'status', 'name', 'customer', 'docReference', 'locationName',
                        ],
                    },
                }, //byDate
                {
                    title: "quickFilter.jobs.byRequiredSkill",
                    groupBy: 'requiredSkill',
                    dataType: 'CSV',
                    columns: {
                        required: [
                            'requiredSkill', 'jobDate', 'status', 'name', 'customer', 'docReference', 'locationName',
                        ],
                    },
                }, //byRequiredSkill
                {
                    title: "quickFilter.jobs.byEpodSignature",
                    filter: {
                        logic: 'and',
                        filters: [
                            {
                                field: 'reqSign',
                                operator: 'eq',
                                value: true,
                            }
                        ],
                    },
                    columns: {
                        required: [
                            'jobList_icon_reqSign', 'jobList_icon_reqPhoto', 'jobDate', 'status', 'name', 'customer', 'docReference', 'locationName',
                        ],
                    },
                }, //byEpodSignature
                {
                    title: "quickFilter.jobs.byEpodPhoto",
                    filter: {
                        logic: 'and',
                        filters: [
                            {
                                field: 'reqPhoto',
                                operator: 'eq',
                                value: true,
                            }
                        ],
                    },
                    columns: {
                        required: [
                            'jobList_icon_reqSign', 'jobList_icon_reqPhoto', 'jobDate', 'status', 'name', 'customer', 'docReference', 'locationName',
                        ],
                    },
                }, //byEpodPhoto
                {
                    title: "quickFilter.jobs.byAttachments",
                    filter: {
                        logic: 'and',
                        filters: [
                            {
                                field: 'attachments',
                                operator: function (data) {
                                    return data
                                        ? data.length != 0
                                        : false
                                },
                            }
                        ],
                    },
                    columns: {
                        required: [
                            'attachments', 'jobList_icon_reqSign', 'jobList_icon_reqPhoto', 'jobDate', 'status', 'name', 'customer', 'docReference', 'locationName',
                        ],
                    },
                }, //byAttachments
                {
                    title: "quickFilter.jobs.byRecurrance",
                    groupBy: 'jobList_isRecurring',
                    dataType: 'Custom',
                    valueConversion: function (value) {
                        return value?.toolTip?.toUpperCase() || '';
                    },
                    columns: {
                        required: [
                            'jobList_isRecurring', 'jobDate', 'status', 'name', 'customer', 'docReference', 'locationName',
                        ],
                    },
                }, //byRecurrance
            ],
            locations: [
                {
                    title: "quickFilter.locations.byGeofenceType",
                    groupName: 'locationsbyGeofenceType',
                    groupBy: {
                        value: 'geozone',
                        dataType: 'String',
                        groupBy: {
                            value: 'category',
                            dataType: 'String',
                            groupBy: {
                                value: 'tag',
                                dataType: 'String',
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'name', 'address', 'category', 'geozone', 'category', 'tag',
                        ],
                    },
                }, //byGeofenceType
                {
                    title: "quickFilter.locations.byCategory",
                    groupName: 'locationsbyCategory',
                    groupBy: {
                        value: 'category',
                        dataType: 'String',
                        groupBy: {
                            value: 'tag',
                            dataType: 'String',
                            groupBy: {
                                value: 'geozone',
                                dataType: 'String',
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'name', 'address', 'category', 'geozone', 'category', 'tag',
                        ],
                    },
                }, //byCategory
                {
                    title: "quickFilter.locations.byType",
                    groupName: 'locationsbyType',
                    groupBy: {
                        value: 'tag',
                        dataType: 'String',
                        groupBy: {
                            value: 'category',
                            dataType: 'String',
                            groupBy: {
                                value: 'geozone',
                                dataType: 'String',
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'name', 'address', 'category', 'geozone', 'category', 'tag',
                        ],
                    },
                }, //byType
                {
                    title: "quickFilter.locations.byProvince",
                    groupName: 'locationsbyProvince',
                    groupBy: {
                        value: 'address',
                        dataType: 'Custom',
                        valueConversion: function (value) {
                            if (value) {
                                let address = value.split(',');
                                let lastNumbericIndex = -1;
                                for (let x in address) {
                                    if (!isNaN(address[x])) {
                                        lastNumbericIndex = x;
                                    };
                                };
                                return lastNumbericIndex > 0
                                    ? address[lastNumbericIndex - 1].trim().toUpperCase()
                                    : ''
                            } else {
                                return '';
                            };
                        },
                        groupBy: {
                            value: 'address',
                            dataType: 'Custom',
                            valueConversion: function (value) {
                                if (value) {
                                    let address = value.split(',');
                                    let lastNumbericIndex = -1;
                                    for (let x in address) {
                                        if (!isNaN(address[x])) {
                                            lastNumbericIndex = x;
                                        };
                                    };
                                    return lastNumbericIndex > 1
                                        ? address[lastNumbericIndex - 2].trim().toUpperCase()
                                        : ''
                                } else {
                                    return '';
                                };
                            },
                            groupBy: {
                                value: 'address',
                                dataType: 'Custom',
                                valueConversion: function (value) {
                                    if (value) {
                                        let address = value.split(',');
                                        let lastNumbericIndex = -1;
                                        for (let x in address) {
                                            if (!isNaN(address[x])) {
                                                lastNumbericIndex = x;
                                            };
                                        };
                                        return lastNumbericIndex > 2
                                            ? address[lastNumbericIndex - 3].trim().toUpperCase()
                                            : ''
                                    } else {
                                        return '';
                                    };
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'name', 'address', 'geozone', 'category', 'tag',
                        ],
                    },
                }, //byProvinceAndCity
            ],
            users: [
                {
                    title: "quickFilter.users.byAssignedRole",
                    groupName: 'usersbyAssignedRole',
                    groupBy: {
                        value: 'rolesAssigned',
                        dataType: 'CSV',
                        groupBy: {
                            value: 'workLocation',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'displayName', 'description', 'rolesAssigned', 'workLocation', 'driverAllowedNotification',
                        ],
                    },
                }, //byAssignedRole
                {
                    title: "quickFilter.users.byNotificationMechanism",
                    groupBy: 'driverAllowedNotification',
                    dataType: 'CSV',
                    columns: {
                        required: [
                            'displayName', 'description', 'rolesAssigned', 'workLocation', 'driverAllowedNotification',
                        ],
                    },
                }, //byNotificationMechanism
                {
                    title: "quickFilter.users.byWorkLocation",
                    groupName: 'usersbyWorkLocation',
                    groupBy: {
                        value: 'workLocation',
                        dataType: 'String',
                        groupBy: {
                            value: 'rolesAssigned',
                            dataType: 'CSV',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'displayName', 'description', 'rolesAssigned', 'workLocation', 'driverAllowedNotification',
                        ],
                    },
                }, //byWorkLocation
            ],
            contacts: [
                {
                    title: "quickFilter.contact.byCompany",
                    groupName: 'contactsbyCompany',
                    groupBy: {
                        value: 'company',
                        dataType: 'String',
                        groupBy: {
                            value: 'state',
                            dataType: 'String',
                            groupBy: {
                                value: 'city',
                                dataType: 'String',
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'description', 'firstName', 'lastName', 'company', 'city', 'state', 'countryOrRegion', 'postalCode',
                        ],
                    },
                }, //byCompany
                {
                    title: "quickFilter.contact.byLocation",
                    groupName: 'contactsbyCity',
                    groupBy: {
                        value: 'countryOrRegion',
                        dataType: 'String',
                        groupBy: {
                            value: 'state',
                            dataType: 'String',
                            groupBy: {
                                value: 'city',
                                dataType: 'String',
                                groupBy: {
                                    value: 'company',
                                    dataType: 'String',
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'description', 'firstName', 'lastName', 'company', 'city', 'state', 'countryOrRegion', 'postalCode',
                        ],
                    },
                }, //byLocation
                {
                    title: "quickFilter.contact.byState",
                    groupName: 'contactsbyState',
                    groupBy: {
                        value: 'state',
                        dataType: 'String',
                        groupBy: {
                            value: 'city',
                            dataType: 'String',
                            groupBy: {
                                value: 'company',
                                dataType: 'String',
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'description', 'firstName', 'lastName', 'company', 'city', 'state', 'countryOrRegion', 'postalCode',
                        ],
                    },
                }, //byState
                {
                    title: "quickFilter.contact.bySVRContact",
                    groupName: 'contactsbySVRContact',
                    groupBy: {
                        value: 'isSVRContact',
                        dataType: 'String',
                        groupBy: {
                            value: 'company',
                            dataType: 'String',
                            groupBy: {
                                value: 'state',
                                dataType: 'String',
                                groupBy: {
                                    value: 'city',
                                    dataType: 'String',
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'description', 'firstName', 'lastName', 'company', 'isSVRContact',
                        ],
                    },
                }, //bySVRContact
            ],
            fleetAlertPolicy: [
                {
                    title: "quickFilter.fleetAlertPolicy.lastTriggerTime",
                    groupName: 'fleetAlertPolicylastTriggerTime',
                    groupBy: {
                        value: 'lastTriggerTime',
                        dataType: 'Date',
                        groupBy: {
                            value: 'triggerName',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'ruleName', 'lastTriggerTime', 'triggerName', 'rulePriority', 'alertOn', 
                        ],
                    },
                }, //lastTriggerTime
                {
                    title: "quickFilter.fleetAlertPolicy.byalertOn",
                    groupName: 'fleetAlertPolicybyalertOn',
                    groupBy: {
                        value: 'alertOnAssets',
                        dataType: 'Array',
                        groupBy: {
                            value: 'triggerName',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'ruleName', 'lastTriggerTime', 'triggerName', 'rulePriority', 'alertOn',
                        ],
                    },
                }, //alertOn
                {
                    title: "quickFilter.fleetAlertPolicy.bytriggerName",
                    groupName: 'fleetAlertPolicybytriggerName',
                    groupBy: {
                        value: 'triggerName',
                        dataType: 'String',
                        groupBy: {
                            value: 'alertOnAssets',
                            dataType: 'Array',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'ruleName', 'lastTriggerTime', 'triggerName', 'rulePriority', 'alertOn',
                        ],
                    },
                }, //triggerName
            ],
            fleetAlertList: [
                {
                    title: "quickFilter.fleetAlerts.byAlertType",
                    groupName: 'ByalertType',
                    groupBy: {
                        value: 'alertType',
                        dataType: 'String',
                        groupBy: {
                            value: 'displayName',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'dismiss', 'rulePolicyName', 'displayName','driverDisplayName','alertType',
                        ],
                    },
                }, //byAlertType
                {
                    title: "quickFilter.fleetAlerts.byAlertPolicy",
                    groupName: 'ByrulePolicyName',
                    groupBy: {
                        value: 'rulePolicyName',
                        dataType: 'String',
                        groupBy: {
                            value: 'displayName',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'dismiss', 'rulePolicyName', 'displayName','driverDisplayName','alertType',
                        ],
                    },
                }, //byAlertPolicy
                 {
                    title: "quickFilter.fleetAlerts.byAlertPriority",
                    groupName: 'Bypriority',
                    groupBy: {
                        value: 'priority',
                        dataType: 'String',
                        groupBy: {
                            value: 'displayName',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'dismiss', 'rulePolicyName', 'displayName','driverDisplayName','alertType','priority'
                        ],
                    },
                }, //byAlertPriority
                {
                    title: "quickFilter.fleetAlerts.byAsset",
                    groupName: 'bydisplayName',
                    groupBy: {
                        value: 'displayName',
                        dataType: 'String',
                        groupBy: {
                            value: 'priority',
                            dataType: 'String',
                            groupBy: {
                                value: 'alertType',
                                dataType: 'String',
                            },
                        }
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'dismiss', 'rulePolicyName', 'displayName','driverDisplayName', 'alertType', 'priority'
                        ],
                    },
                }, //bydisplayName
                {
                    title: "quickFilter.fleetAlerts.byDriverDisplayName",
                    groupName: 'bySriverDisplayName',
                    groupBy: {
                        value: 'driverDisplayName',
                        dataType: 'String',
                        groupBy: {
                            value: 'priority',
                            dataType: 'String',
                            groupBy: {
                                value: 'alertType',
                                dataType: 'String',
                            },
                        }
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'dismiss', 'rulePolicyName', 'displayName','driverDisplayName', 'alertType', 'priority'
                        ],
                    },
                }, //bydriverDisplayName
            ],
            vendors: [
                {
                    title: "quickFilter.vendor.ByVendor",
                    groupName: 'ByVendorName',
                    groupBy: {
                        value: 'name',
                        dataType: 'String',
                        groupBy: {
                            value: 'vendorCity',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'name', 'vendorCity', 'contactName', 'contactEmail', 'contactNumber',
                        ],
                    },
                }, //ByVendorName
                {
                    title: "quickFilter.vendor.ByCity",
                    groupName: 'ByCity',
                    groupBy: {
                        value: 'vendorCity',
                        dataType: 'String',
                        groupBy: {
                            value: 'name',
                            dataType: 'String',
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'name', 'vendorCity', 'contactName', 'contactEmail', 'contactNumber',
                        ],
                    },
                }, //ByVendorName
            ],
            //#region AMM
            assetLifeCyclesListed: [
                {
                    title: "quickFilter.lifeCycle.ByAssetTypeName",
                    groupBy: 'assetTypeName',
                    dataType: 'String',
                    columns: {
                        required: [
                            'selected', 'commands', 'displayName', 'assetTypeName',
                        ],
                    },
                }, //ByAssetTypeName
                {
                    title: "quickFilter.lifeCycle.ByPurchaseDate",
                    groupBy: 'purchaseDate',
                    dataType: 'Date',
                    columns: {
                        required: [
                            'selected', 'commands', 'displayName', 'purchaseDate',
                        ],
                    },
                }, //ByPurchaseDate
                {
                    title: "quickFilter.lifeCycle.ByFinancing",
                    groupBy: 'financing',
                    dataType: 'String',
                    columns: {
                        required: [
                            'selected', 'commands', 'displayName', 'purchaseDate', 'purchaseAmount', 'financing'
                        ],
                    },
                }, //ByFinancing
                {
                    title: "quickFilter.lifeCycle.ByActivationDate",
                    groupBy: 'activationDate',
                    dataType: 'Date',
                    columns: {
                        required: [
                            'selected', 'commands', 'displayName', 'activationDate', 'activationODO'
                        ],
                    },
                }, //ByActivationDate
                {
                    title: "quickFilter.lifeCycle.ByExpirationDate",
                    groupBy: 'expirationDate',
                    dataType: 'Date',
                    columns: {
                        required: [
                            'selected', 'commands', 'displayName', 'maxOdoAllowed', 'expirationDate'
                        ],
                    },
                }, //ByExpirationDate
                {
                    title: "quickFilter.lifeCycle.ByMaxOdoAllowed",
                    groupBy: 'maxOdoAllowed',
                    dataType: 'String',
                    columns: {
                        required: [
                            'selected', 'commands', 'displayName', 'maxOdoAllowed', 'expirationDate'
                        ],
                    },
                }, //ByMaxOdoAllowed
                {
                    title: "quickFilter.lifeCycle.ByInMonths",
                    groupBy: 'inMonths',
                    dataType: 'String',
                    columns: {
                        required: [
                            'selected', 'commands', 'displayName', 'inMonths', 'ODO', 'avgWorkingHoursPerDay'
                        ],
                    },
                }, //ByInMonths
                {
                    title: "quickFilter.lifeCycle.ByODO",
                    groupBy: 'ODO',
                    dataType: 'String',
                    columns: {
                        required: [
                            'selected', 'commands', 'displayName', 'inMonths', 'ODO', 'avgWorkingHoursPerDay'
                        ],
                    },
                }, //ByODO
                {
                    title: "quickFilter.lifeCycle.ByAvgWorkingHoursPerDay",
                    groupBy: 'avgWorkingHoursPerDay',
                    dataType: 'String',
                    columns: {
                        required: [
                            'selected', 'commands', 'displayName', 'inMonths', 'ODO', 'avgWorkingHoursPerDay'
                        ],
                    },
                }, //ByAvgWorkingHoursPerDay
                {
                    title: "quickFilter.lifeCycle.ByDepreciationStrategy",
                    groupBy: 'depreciationStrategy',
                    dataType: 'String',
                    columns: {
                        required: [
                            'selected', 'commands', 'displayName', 'depreciationStrategy', 'annualDepreciationValue'
                        ],
                    },
                }, //ByDepreciationStrategy
                {
                    title: "quickFilter.lifeCycle.ByRetirementDate",
                    groupBy: 'retirementDate',
                    dataType: 'Date',
                    columns: {
                        required: [
                            'selected', 'commands', 'displayName', 'retirementDate', 'retirementODO', 'saleValue'
                        ],
                    },
                }, //ByRetirementDate
            ],
            assetsTransactionalHistory: [   
                {
                    title: "quickFilter.transactionHistory.ByAsset",
                    groupName: 'ByAsset',
                    groupBy: 'assetDisplayName',
                    dataType: 'String',
                    columns: {
                        required: [
                            'selected', 'commands', 'assetDisplayName', 'date', 'amount',
                        ],
                    },
                }, //ByAsset
                {
                    title: "quickFilter.transactionHistory.ByType",
                    groupName: 'ByType',
                    groupBy: {
                        value: 'type',
                        dataType: 'String',
                        groupBy: {
                            value: 'category',
                            dataType: 'String',
                            groupBy: {
                                value: 'subCategory',
                                dataType: 'String',
                                groupBy: {
                                    value: 'recurranceDisplay',
                                    dataType: 'String',
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'assetDisplayName', 'date', 'type', 'amount', 'category', 'subCategory', 'recurranceDisplay',
                        ],
                    },
                }, //ByType
                {
                    title: "quickFilter.transactionHistory.ByVendor",
                    groupName: 'ByVendor',
                    groupBy: {
                        value: 'vendor',
                        dataType: 'Custom',
                        valueConversion: function (value) {
                            return value.id
                                ? value.name.split('-')[0]?.trim() || ''
                                : ''
                        },
                        groupBy: {
                            value: 'vendor',
                            dataType: 'Custom',
                            valueConversion: function (value) {
                                return value.id
                                    ? value.name.split('-')[1]?.split("(")[0]?.trim() || ''
                                    : ''
                            },
                            groupBy: {
                                value: 'vendor',
                                dataType: 'Custom',
                                valueConversion: function (value) {
                                    return value.id
                                        ? value.name.split('-')[1]?.split("(")[1]?.replace(")","").trim() || ''
                                        : ''
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'assetDisplayName', 'date', 'amount', 'vendorName', 
                        ],
                    },
                }, //ByVendor
                {
                    title: "quickFilter.transactionHistory.ByCustomer",
                    groupName: 'ByCustomer',
                    groupBy: {
                        value: 'vendor',
                        dataType: 'Custom',
                        valueConversion: function (value) {
                            return !value.id
                                ? value.name.split('-')[0]?.trim() || ''
                                : ''
                        },
                        groupBy: {
                            value: 'vendor',
                            dataType: 'Custom',
                            valueConversion: function (value) {
                                return !value.id
                                    ? value.name.split('-')[1]?.split("(")[0]?.trim() || ''
                                    : ''
                            },
                            groupBy: {
                                value: 'vendor',
                                dataType: 'Custom',
                                valueConversion: function (value) {
                                    return !value.id
                                        ? value.name.split('-')[1]?.split("(")[1]?.replace(")", "").trim() || ''
                                        : ''
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'assetDisplayName', 'date', 'amount', 'vendorName',
                        ],
                    },
                }, //ByCustomer
                {
                    title: "quickFilter.transactionHistory.ByDate",
                    groupName: 'ByDate',
                    groupBy: 'date',
                    dataType: 'Date',
                    columns: {
                        required: [
                            'selected', 'commands', 'assetDisplayName', 'date',  'amount',
                        ],
                    },
                }, //ByDate
                {
                    title: "quickFilter.transactionHistory.ByRecurrance",
                    groupName: 'ByRecurrance',
                    groupBy: {
                        value: 'recurranceDisplay',
                        dataType: 'String',
                        groupBy: {
                            value: 'type',
                            dataType: 'String',
                            groupBy: {
                                value: 'category',
                                dataType: 'String',
                                groupBy: {
                                    value: 'subCategory',
                                    dataType: 'String',
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'assetDisplayName', 'date', 'type', 'amount', 'category', 'subCategory', 'recurranceDisplay'
                        ],
                    },
                }, //ByRecurrance
                
            ],
            assetDetailedTransactionalHistory: [
                {
                    title: "quickFilter.transactionHistory.ByType",
                    groupName: 'ByType',
                    groupBy: {
                        value: 'type',
                        dataType: 'String',
                        groupBy: {
                            value: 'category',
                            dataType: 'String',
                            groupBy: {
                                value: 'subCategory',
                                dataType: 'String',
                                groupBy: {
                                    value: 'recurranceDisplay',
                                    dataType: 'String',
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'date', 'type', 'amount', 'category', 'subCategory', 'recurranceDisplay',
                        ],
                    },
                }, //ByType
                {
                    title: "quickFilter.transactionHistory.ByVendor",
                    groupName: 'ByVendor',
                    groupBy: {
                        value: 'vendor',
                        dataType: 'Custom',
                        valueConversion: function (value) {
                            return value.id
                                ? value.name.split('-')[0]?.trim() || ''
                                : ''
                        },
                        groupBy: {
                            value: 'vendor',
                            dataType: 'Custom',
                            valueConversion: function (value) {
                                return value.id
                                    ? value.name.split('-')[1]?.split("(")[0]?.trim() || ''
                                    : ''
                            },
                            groupBy: {
                                value: 'vendor',
                                dataType: 'Custom',
                                valueConversion: function (value) {
                                    return value.id
                                        ? value.name.split('-')[1]?.split("(")[1]?.replace(")", "").trim() || ''
                                        : ''
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'date', 'amount', 'vendorName',
                        ],
                    },
                }, //ByVendor
                {
                    title: "quickFilter.transactionHistory.ByCustomer",
                    groupName: 'ByCustomer',
                    groupBy: {
                        value: 'vendor',
                        dataType: 'Custom',
                        valueConversion: function (value) {
                            return !value.id
                                ? value.name.split('-')[0]?.trim() || ''
                                : ''
                        },
                        groupBy: {
                            value: 'vendor',
                            dataType: 'Custom',
                            valueConversion: function (value) {
                                return !value.id
                                    ? value.name.split('-')[1]?.split("(")[0]?.trim() || ''
                                    : ''
                            },
                            groupBy: {
                                value: 'vendor',
                                dataType: 'Custom',
                                valueConversion: function (value) {
                                    return !value.id
                                        ? value.name.split('-')[1]?.split("(")[1]?.replace(")", "").trim() || ''
                                        : ''
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands',  'date', 'amount', 'vendorName',
                        ],
                    },
                }, //ByCustomer
                {
                    title: "quickFilter.transactionHistory.ByDate",
                    groupName: 'ByDate',
                    groupBy: 'date',
                    dataType: 'Date',
                    columns: {
                        required: [
                            'selected', 'commands',  'date', 'amount',
                        ],
                    },
                }, //ByDate
                {
                    title: "quickFilter.transactionHistory.ByRecurrance",
                    groupName: 'ByRecurrance',
                    groupBy: {
                        value: 'recurranceDisplay',
                        dataType: 'String',
                        groupBy: {
                            value: 'type',
                            dataType: 'String',
                            groupBy: {
                                value: 'category',
                                dataType: 'String',
                                groupBy: {
                                    value: 'subCategory',
                                    dataType: 'String',
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'date', 'type', 'amount', 'category', 'subCategory', 'recurranceDisplay'
                        ],
                    },
                }, //ByRecurrance

            ],
            operatorExpenseManagement: [
                {
                    title: "quickFilter.operators.ByCostModel",
                    groupName: 'ByCostModel',
                    groupBy: 'costModel',
                    dataType: 'String',
                    columns: {
                        required: [
                            'selected', 'commands', 'displayName', 'costModel',
                        ],
                    },
                }, //ByCostModel
            ],
            operatorsTransactionalHistory: [
                {
                    title: "quickFilter.transactionHistory.ByOperator",
                    groupName: 'ByOperator',
                    groupBy: 'operatorDisplayName',
                    dataType: 'String',
                    columns: {
                        required: [
                            'selected', 'commands', 'operatorDisplayName', 'date', 'amount',
                        ],
                    },
                }, //ByOperator
                {
                    title: "quickFilter.transactionHistory.ByType",
                    groupName: 'ByType',
                    groupBy: {
                        value: 'type',
                        dataType: 'String',
                        groupBy: {
                            value: 'category',
                            dataType: 'String',
                            groupBy: {
                                value: 'subCategory',
                                dataType: 'String',
                                groupBy: {
                                    value: 'recurranceDisplay',
                                    dataType: 'String',
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'operatorDisplayName', 'date', 'type', 'amount', 'category', 'subCategory', 'recurranceDisplay',
                        ],
                    },
                }, //ByType
                {
                    title: "quickFilter.transactionHistory.ByVendor",
                    groupName: 'ByVendor',
                    groupBy: {
                        value: 'vendor',
                        dataType: 'Custom',
                        valueConversion: function (value) {
                            return value.id
                                ? value.name.split('-')[0]?.trim() || ''
                                : ''
                        },
                        groupBy: {
                            value: 'vendor',
                            dataType: 'Custom',
                            valueConversion: function (value) {
                                return value.id
                                    ? value.name.split('-')[1]?.split("(")[0]?.trim() || ''
                                    : ''
                            },
                            groupBy: {
                                value: 'vendor',
                                dataType: 'Custom',
                                valueConversion: function (value) {
                                    return value.id
                                        ? value.name.split('-')[1]?.split("(")[1]?.replace(")", "").trim() || ''
                                        : ''
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'operatorDisplayName', 'date', 'amount', 'vendorName',
                        ],
                    },
                }, //ByVendor
                {
                    title: "quickFilter.transactionHistory.ByCustomer",
                    groupName: 'ByCustomer',
                    groupBy: {
                        value: 'vendor',
                        dataType: 'Custom',
                        valueConversion: function (value) {
                            return !value.id
                                ? value.name.split('-')[0]?.trim() || ''
                                : ''
                        },
                        groupBy: {
                            value: 'vendor',
                            dataType: 'Custom',
                            valueConversion: function (value) {
                                return !value.id
                                    ? value.name.split('-')[1]?.split("(")[0]?.trim() || ''
                                    : ''
                            },
                            groupBy: {
                                value: 'vendor',
                                dataType: 'Custom',
                                valueConversion: function (value) {
                                    return !value.id
                                        ? value.name.split('-')[1]?.split("(")[1]?.replace(")", "").trim() || ''
                                        : ''
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'operatorDisplayName', 'date', 'amount', 'vendorName',
                        ],
                    },
                }, //ByCustomer
                {
                    title: "quickFilter.transactionHistory.ByDate",
                    groupName: 'ByDate',
                    groupBy: 'date',
                    dataType: 'Date',
                    columns: {
                        required: [
                            'selected', 'commands', 'operatorDisplayName', 'date', 'amount',
                        ],
                    },
                }, //ByDate
                {
                    title: "quickFilter.transactionHistory.ByRecurrance",
                    groupName: 'ByRecurrance',
                    groupBy: {
                        value: 'recurranceDisplay',
                        dataType: 'String',
                        groupBy: {
                            value: 'type',
                            dataType: 'String',
                            groupBy: {
                                value: 'category',
                                dataType: 'String',
                                groupBy: {
                                    value: 'subCategory',
                                    dataType: 'String',
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'operatorDisplayName', 'date', 'type', 'amount', 'category', 'subCategory', 'recurranceDisplay'
                        ],
                    },
                }, //ByRecurrance
            ],
            operatorDetailedTransactionalHistory: [
                {
                    title: "quickFilter.transactionHistory.ByType",
                    groupName: 'ByType',
                    groupBy: {
                        value: 'type',
                        dataType: 'String',
                        groupBy: {
                            value: 'category',
                            dataType: 'String',
                            groupBy: {
                                value: 'subCategory',
                                dataType: 'String',
                                groupBy: {
                                    value: 'recurranceDisplay',
                                    dataType: 'String',
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'date', 'type', 'amount', 'category', 'subCategory', 'recurranceDisplay',
                        ],
                    },
                }, //ByType
                {
                    title: "quickFilter.transactionHistory.ByVendor",
                    groupName: 'ByVendor',
                    groupBy: {
                        value: 'vendor',
                        dataType: 'Custom',
                        valueConversion: function (value) {
                            return value.id
                                ? value.name.split('-')[0]?.trim() || ''
                                : ''
                        },
                        groupBy: {
                            value: 'vendor',
                            dataType: 'Custom',
                            valueConversion: function (value) {
                                return value.id
                                    ? value.name.split('-')[1]?.split("(")[0]?.trim() || ''
                                    : ''
                            },
                            groupBy: {
                                value: 'vendor',
                                dataType: 'Custom',
                                valueConversion: function (value) {
                                    return value.id
                                        ? value.name.split('-')[1]?.split("(")[1]?.replace(")", "").trim() || ''
                                        : ''
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'date', 'amount', 'vendorName',
                        ],
                    },
                }, //ByVendor
                {
                    title: "quickFilter.transactionHistory.ByCustomer",
                    groupName: 'ByCustomer',
                    groupBy: {
                        value: 'vendor',
                        dataType: 'Custom',
                        valueConversion: function (value) {
                            return !value.id
                                ? value.name.split('-')[0]?.trim() || ''
                                : ''
                        },
                        groupBy: {
                            value: 'vendor',
                            dataType: 'Custom',
                            valueConversion: function (value) {
                                return !value.id
                                    ? value.name.split('-')[1]?.split("(")[0]?.trim() || ''
                                    : ''
                            },
                            groupBy: {
                                value: 'vendor',
                                dataType: 'Custom',
                                valueConversion: function (value) {
                                    return !value.id
                                        ? value.name.split('-')[1]?.split("(")[1]?.replace(")", "").trim() || ''
                                        : ''
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'date', 'amount', 'vendorName',
                        ],
                    },
                }, //ByCustomer
                {
                    title: "quickFilter.transactionHistory.ByDate",
                    groupName: 'ByDate',
                    groupBy: 'date',
                    dataType: 'Date',
                    columns: {
                        required: [
                            'selected', 'commands', 'date', 'amount',
                        ],
                    },
                }, //ByDate
                {
                    title: "quickFilter.transactionHistory.ByRecurrance",
                    groupName: 'ByRecurrance',
                    groupBy: {
                        value: 'recurranceDisplay',
                        dataType: 'String',
                        groupBy: {
                            value: 'type',
                            dataType: 'String',
                            groupBy: {
                                value: 'category',
                                dataType: 'String',
                                groupBy: {
                                    value: 'subCategory',
                                    dataType: 'String',
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'date', 'type', 'amount', 'category', 'subCategory', 'recurranceDisplay'
                        ],
                    },
                }, //ByRecurrance
            ],
            transactionalHistory: [
                {
                    title: "quickFilter.transactionHistory.ByAsset",
                    groupName: 'ByAsset',
                    groupBy: 'assetDisplayName',
                    dataType: 'String',
                    columns: {
                        required: [
                            'selected', 'commands', 'assetDisplayName', 'date', 'amount',
                        ],
                    },
                }, //ByAsset
                {
                    title: "quickFilter.transactionHistory.ByOperator",
                    groupName: 'ByOperator',
                    groupBy: 'operatorDisplayName',
                    dataType: 'String',
                    columns: {
                        required: [
                            'selected', 'commands', 'operatorDisplayName', 'date', 'amount',
                        ],
                    },
                }, //ByOperator
                {
                    title: "quickFilter.transactionHistory.ByType",
                    groupName: 'ByType',
                    groupBy: {
                        value: 'type',
                        dataType: 'String',
                        groupBy: {
                            value: 'category',
                            dataType: 'String',
                            groupBy: {
                                value: 'subCategory',
                                dataType: 'String',
                                groupBy: {
                                    value: 'recurranceDisplay',
                                    dataType: 'String',
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'date', 'type', 'amount', 'category', 'subCategory', 'recurranceDisplay',
                        ],
                    },
                }, //ByType
                {
                    title: "quickFilter.transactionHistory.ByVendor",
                    groupName: 'ByVendor',
                    groupBy: {
                        value: 'vendor',
                        dataType: 'Custom',
                        valueConversion: function (value) {
                            return value.id
                                ? value.name.split('-')[0]?.trim() || ''
                                : ''
                        },
                        groupBy: {
                            value: 'vendor',
                            dataType: 'Custom',
                            valueConversion: function (value) {
                                return value.id
                                    ? value.name.split('-')[1]?.split("(")[0]?.trim() || ''
                                    : ''
                            },
                            groupBy: {
                                value: 'vendor',
                                dataType: 'Custom',
                                valueConversion: function (value) {
                                    return value.id
                                        ? value.name.split('-')[1]?.split("(")[1]?.replace(")", "").trim() || ''
                                        : ''
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'date', 'amount', 'vendorName',
                        ],
                    },
                }, //ByVendor
                {
                    title: "quickFilter.transactionHistory.ByCustomer",
                    groupName: 'ByCustomer',
                    groupBy: {
                        value: 'vendor',
                        dataType: 'Custom',
                        valueConversion: function (value) {
                            return !value.id
                                ? value.name.split('-')[0]?.trim() || ''
                                : ''
                        },
                        groupBy: {
                            value: 'vendor',
                            dataType: 'Custom',
                            valueConversion: function (value) {
                                return !value.id
                                    ? value.name.split('-')[1]?.split("(")[0]?.trim() || ''
                                    : ''
                            },
                            groupBy: {
                                value: 'vendor',
                                dataType: 'Custom',
                                valueConversion: function (value) {
                                    return !value.id
                                        ? value.name.split('-')[1]?.split("(")[1]?.replace(")", "").trim() || ''
                                        : ''
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'date', 'amount', 'vendorName',
                        ],
                    },
                }, //ByCustomer
                {
                    title: "quickFilter.transactionHistory.ByDate",
                    groupName: 'ByDate',
                    groupBy: 'date',
                    dataType: 'Date',
                    columns: {
                        required: [
                            'selected', 'commands', 'date', 'amount',
                        ],
                    },
                }, //ByDate
                {
                    title: "quickFilter.transactionHistory.ByRecurrance",
                    groupName: 'ByRecurrance',
                    groupBy: {
                        value: 'recurranceDisplay',
                        dataType: 'String',
                        groupBy: {
                            value: 'type',
                            dataType: 'String',
                            groupBy: {
                                value: 'category',
                                dataType: 'String',
                                groupBy: {
                                    value: 'subCategory',
                                    dataType: 'String',
                                },
                            },
                        },
                    },
                    dataType: 'SubGroup',
                    columns: {
                        required: [
                            'selected', 'commands', 'date', 'type', 'amount', 'category', 'subCategory', 'recurranceDisplay'
                        ],
                    },
                }, //ByRecurrance
            ],
            //#endregion
        },
        requiredFields: {
            vehicles: ['id', 'alertPolicies', 'model', 'make', 'year', 'vehicleStatus', 'vehicleStatusValue', 'fuelType', 'entityWarnings', 'cameras', 'tachoEnabled', 'devicesList', 'tagEnabledTXT'],
            vehiclesSideDrawer: ['id', 'assignedDriver', 'make', 'model', 'odometer', 'productFeatures','devicesList', 'profileImage', 'registrationNumber', 'runningHours', 'tachoEnabled', 'tagEnabledTXT', 'traceCellNo', 'vehicleIcon', 'vehicleStatus','year'],
        },
        pageNames: {
            videos: 'Videos',
            fleetAlertPolicy: 'Fleet Alerts',
            vehicles: 'Assets',
            drivers: 'Operators',
            ServicePlans: 'Service Plan',
            serviceHistory: 'Service Plan History',
            LicensingLists: 'Licensing',
            InspectionLists: 'Inspection Lists',
            JobListFiltered: 'Jobs',
            locations: 'Locations',
            users: 'Users',
            contacts: 'Contacts',
            routes : 'Routes',
        },
    }),

    actions: {
        getColumns() {
            $appModel.configProperties.columnConfig.forEach(column => {
                if (column.name !== null && column.name !== undefined && column.name.includes("crystal")) {
                    this.columns.push(column);
                };
            });
            this.columns.forEach(configColumn => {
                if (configColumn.widths !== null && configColumn.widths !== undefined) {
                    configColumn.widths.split(',').forEach(width => {
                        let columnIndex = this[configColumn.name].findIndex(column => width.includes(column.field));
                        if (columnIndex !== -1) {
                            let configWidth = width.split(';')[1];
                            if (configWidth !== null && configWidth !== undefined) {
                                this[configColumn.name][columnIndex].width = configWidth;
                            };
                        };
                    });
                };
                if (configColumn.order !== null && configColumn.order !== undefined) {
                    configColumn.order.split(',').forEach(order => {
                        let columnIndex = this[configColumn.name].findIndex(column => order.includes(column.field));
                        if (columnIndex !== -1) {
                            let configOrderIndex = order.split(';')[1];
                            if (configOrderIndex !== null && configOrderIndex !== undefined) {
                                this[configColumn.name][columnIndex].orderIndex = configOrderIndex;
                            };
                        };
                    });
                };
            });
        },
        saveColumns(name) {
            if (name !== null && name !== undefined) {
                let columns = this[name];
                let columnIndex = $appModel.configProperties.columnConfig.findIndex(column => column.name === name);
                let currentColumn = {
                    name: name,
                    widths: columns.map(column => {
                        return [column.field] + ";" + column.width;
                    }).join(','),
                    order: columns.map(column => {
                        return [column.field] + ";" + column.orderIndex
                    }).join(','),
                };
                if (columnIndex !== -1) {
                    $appModel.configProperties.columnConfig[columnIndex] = currentColumn;
                } else {
                    $appModel.configProperties.columnConfig.push(currentColumn);
                };
                let model = {
                    overrideProperties: {
                        columnConfig: $appModel.configProperties.columnConfig,
                    },
                };
                configurationService.updateUserConfigProperty(model);
            };
        },
    },
})
