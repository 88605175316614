/*jshint esversion: 6 */
import axios from "axios";
import Vue from "vue";
import moment from "moment";
import Router from "vue-router";
import Constant from "../constant";
import * as i18n from "../src/shared/i18n";
import { mutations } from "../src/shared/observeFilter";
import toast from './shared/toast';
import { UserSettings } from "./views/UserSettingsMixin";
import store from "./store/index"
import { ReplaySelectionContainer } from "ctrack-shared-replay";
import { useGroupsFilterStore } from '@/stores/groups-store';
import { useInsightsStore } from '@/stores/insights-store';
const authChannel = new BroadcastChannel('auth');

authChannel.onmessage = (event) => {
    if (event.data === 'logout') {
        router.push({ name: 'login' });
    }
}

Vue.use(Router);
export const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "login",
            component: () => import(/* webpackChunkName: "login" */"./views/Login.vue"),
        },
        {
            path: "*",
            component: () => import(/* webpackChunkName: "login" */ "./views/Login.vue"),
        },
        {
            path: "/forgotpassword",
            name: "forgotpassword",
            component: () => import(/* webpackChunkName: "forgotPassword" */ "./views/forgotpassword.vue"),
        },
        {
            path: "/forgotconfirmationpassword",
            name: "forgotconfirmationpassword",
            component: () => import(/* webpackChunkName: "forgotPassword" */"./views/forgotconfirmationpassword.vue"),
        },
        {
            path: "/resetpassword",
            name: "resetpassword",
            component: () => import(/* webpackChunkName: "resetPassword" */ "./views/resetpassword.vue"),
        },
        {
            path: "/otpLogin",
            name: "otpLogin",
            component: () => import(/* webpackChunkName: "otpLogin" */ "./views/otpLogin.vue"),
        },
        {
            path: "/reports/:name",
            name: "reportViewer",
            component: () =>
                import(/* webpackChunkName: "report" */ "./views/reports/reportViewer.vue"),
        },
        {
            path: "/reports/snapshot/:name",
            name: "snapshotReportViewer",
            component: () =>
                import(/* webpackChunkName: "snapshotReport" */ "./views/snapshots/snapshotReportViewer.vue"),
        },
        {
            path: "/trace/:id",
            name: "trace",
            component: () => import(/* webpackChunkName: "trace" */ "./components/livemap/livemap-trace-container.vue"),
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: () => import(/* webpackChunkName: "dashboard" */ "./views/Dashboard/Dashboard.vue"),
            children: [
                {
                    path: "/dashboard/qlikApp",
                    name: "QlikSenseRead",
                    component: () => import(/* webpackChunkName: "user" */ "./views/Qliksense/app.vue"),
                },
                {
                    path: "/dashboard/user",
                    name: "userRead",
                    component: () => import(/* webpackChunkName: "user" */ "./views/User/User.vue"),
                },
                {
                    path: "/dashboard/user/add",
                    name: "userCreate",
                    component: () => import(/* webpackChunkName: "user" */ "./views/User/AddUser.vue"),
                },
                {
                    path: "/dashboard/user/edit/:id",
                    name: "userUpdate",
                    component: () => import(/* webpackChunkName: "user" */ "./views/User/AddUser.vue"),
                },
                {
                    path: "/dashboard/Contact",
                    name: "contactRead",
                    component: () => import(/* webpackChunkName: "contact" */ "./views/Contact/Contact.vue"),
                },
                {
                    path: "/dashboard/Contact/add",
                    name: "contactCreate",
                    component: () =>
                        import(/* webpackChunkName: "contact" */ "./views/Contact/AddContact.vue"),
                },
                {
                    path: "/dashboard/Contact/edit/:id",
                    name: "contactUpdate",
                    component: () =>
                        import(/* webpackChunkName: "contact" */ "./views/Contact/AddContact.vue"),
                },
                {
                    path: "/dashboard/replay",
                    name: "replayRead",
                    component: ReplaySelectionContainer
                },
                {
                    path: "/dashboard/replay-details",
                    name: "replaydetails",
                    component: () =>
                        import(/* webpackChunkName: "contact" */ "./components/replay/replay-details.vue"),
                },
                {
                    path: "/dashboard/videos",
                    name: "videos",
                    component: () => import(/* webpackChunkName: "videos" */ "./views/Video/Videos.vue"),
                },
                {
                    path: "/dashboard/jobs",
                    name: "jobsRead",
                    component: () =>
                        /*import(*//* webpackChunkName: "job" *//* "./views/TaskManagement/jobList.vue"),*/
                        import(/* webpackChunkName: "job" */ "./views/task-management/jobs.vue"),
                },
                {
                    path: "/dashboard/routes",
                    name: "manageRouteRead",
                    component: () =>
                        import(/* webpackChuckName: "route" */ "./views/routes/routes.vue")
                },
                {
                    path: "/dashboard/snapshot",
                    name: "snapshotRead",
                    component: () =>
                        import(/* webpackChunkName: "snapshot" */ "./components/snapshots/snapshots.vue"),
                },
                {
                    path: "/dashboard/location",
                    name: "locationRead",
                    component: () =>
                        import(/* webpackChunkName: "location" */ "./views/Locations/Locations.vue"),
                },
                {
                    path: "/dashboard/location/add",
                    name: "locationCreate",
                    component: () =>
                        import(/* webpackChunkName: "location" */ "./views/Locations/addLocation.vue"),
                },
                {
                    path: "/dashboard/location/edit/:id",
                    name: "locationUpdate",
                    component: () =>
                        import(/* webpackChunkName: "location" */ "./views/Locations/editLocation.vue"),
                },
                {
                    path: "/dashboard/vehicles",
                    name: "vehiclesRead",
                    component: () =>
                        import(/* webpackChunkName: "asset" */ "./views/Vehicles/Vehicles.vue"),
                },
                {
                    path: "/dashboard/vehicles/edit/:id",
                    name: "vehiclesUpdate",
                    component: () =>
                        import(/* webpackChunkName: "asset" */ "./views/Vehicles/editVehicle.vue"),
                },
                {
                    path: "/dashboard/drivers",
                    name: "driversRead",
                    component: () => import(/* webpackChunkName: "operator" */ "./views/Drivers/Drivers.vue"),
                },
                {
                    path: "/dashboard/drivers/add",
                    name: "driversCreate",
                    component: () =>
                        import(/* webpackChunkName: "operator" */ "./views/Drivers/addDriver.vue"),
                },
                {
                    path: "/dashboard/drivers/edit/:id",
                    name: "driversUpdate",
                    component: () =>
                        import(/* webpackChunkName: "operator" */ "./views/Drivers/addDriver.vue"),
                },
                {
                    path: "/dashboard/livemap",
                    name: "livemapRead",
                    component: () => import(/* webpackChunkName: "livemap" */ "./components/livemap/livemap-container.vue"),
                },
                {
                    path: "/dashboard/bulk-import",
                    name: "bulkImportRead",
                    component: () =>
                        import(/* webpackChunkName: "bulkImport" */ "./views/Import/BulkImport.vue"),
                },
                {
                    path: "/dashboard/assetmaintenance/:name?",
                    name: "vehicleMaintenanceRead",
                    component: () =>
                        import(/* webpackChunkName: "assetMaintenance" */ "./views/asset-maintenance/asset-maintenance.vue"),
                },
                {
                    path: "/dashboard/fleetmanagement/:name?",
                    name: "AMMRead",
                    component: () =>
                        import(/* webpackChunkName: "assetMaintenance" */ "./views/management-module/managementModule.vue"),
                },
                {
                    path: "/dashboard/vendors",
                    name: "vendorRead",
                    component: () =>
                        import(/* webpackChunkName: "assetMaintenance" */ "./views/Vendor/Vendors.vue"),
                },
                {
                    path: "/dashboard/vehiclemaintenance",
                    name: "oldVehicleMaintenanceRead",
                    component: () =>
                        import(/* webpackChunkName: "vehicleMaintenance" */ "./views/VehicleMaintenance/vehiclemaintenance.vue"),
                    children: [
                        {
                            path:
                                "/dashboard/vehiclemaintenance/inspection-lists",
                            name: "vehicleMaintenanceInspectionListsRead",
                            component: () =>
                                import(/* webpackChunkName: "vehicleMaintenance" */ "./views/VehicleMaintenance/InspectionLists.vue"),
                        },
                        {
                            path:
                                "/dashboard/vehiclemaintenance/licensing-lists",
                            name: "vehicleMaintenanceLicensingListsRead",
                            component: () =>
                                import(/* webpackChunkName: "vehicleMaintenance" */ "./views/VehicleMaintenance/Licensing.vue"),
                        },
                        {
                            path:
                                "/dashboard/vehiclemaintenance/licensing-lists/add",
                            name: "vehicleMaintenanceLicensingListsAdd",
                            component: () =>
                                import(/* webpackChunkName: "vehicleMaintenance" */ "./views/VehicleMaintenance/AddLicensing.vue"),
                        },
                        {
                            path:
                                "/dashboard/vehiclemaintenance/licensing-lists/edit/:id",
                            name: "vehicleMaintenanceLicensingListsUpdate",
                            component: () =>
                                import(/* webpackChunkName: "vehicleMaintenance" */ "./views/VehicleMaintenance/AddLicensing.vue"),
                        },
                        //  {
                        //     path: "/dashboard/vehiclemaintenance/newServicePlan",
                        //     name: "newServicePlanCreate",
                        //     component: () =>
                        //       import("./views/VehicleMaintenance/addServicePlan.vue"),
                        //   },
                        {
                            path:
                                "/dashboard/vehiclemaintenance/serviceplan-lists",
                            name: "vehicleMaintenanceServicePlanListsRead",
                            component: () =>
                                import(/* webpackChunkName: "vehicleMaintenance" */ "./views/VehicleMaintenance/ServicePlans.vue"),
                        },
                        {
                            path:
                                "/dashboard/vehiclemaintenance/servicehistory-lists",
                            name: "vehicleMaintenanceServiceHistoryListsRead",
                            component: () =>
                                import(/* webpackChunkName: "vehicleMaintenance" */ "./views/VehicleMaintenance/serviceHistory.vue"),
                        },
                        {
                            path:
                                "/dashboard/vehiclemaintenance/serviceplan-lists/add",
                            name: "vehicleMaintenanceServicePlanListsAdd",
                            component: () =>
                                import(/* webpackChunkName: "vehicleMaintenance" */ "./views/VehicleMaintenance/AddServicePlans.vue"),
                        },
                        {
                            path:
                                "/dashboard/vehiclemaintenance/serviceplan-lists/edit/:id",
                            name: "vehicleMaintenanceServicePlanListsUpdate",
                            component: () =>
                                import(/* webpackChunkName: "vehicleMaintenance" */ "./views/VehicleMaintenance/AddServicePlans.vue"),
                        },
                    ],
                },

                {
                    path: "/dashboard/manageassigment",
                    name: "manageAssigmentRead",
                    component: () =>
                        import(/* webpackChunkName: "manageAssigment" */ "./views/ManageAssigment/ManageAssigment.vue"),
                },
                {
                    path: "/dashboard/schedule",
                    name: "scheduleRead",
                    component: () =>
                        import(/* webpackChunkName: "schedule" */ "./views/schedules/schedules.vue"),
                },
                {
                    path: "/dashboard/lpd",
                    name: "LPDRead",
                    component: () => import(/* webpackChunkName: "lpd" */ "./components/live-productivity-dashboard/live-productivity-container.vue"),
                },
                {
                    path: "/dashboard/fleetalerts/AlertPolicies",
                    name: "alertActivityRead",
                    component: () =>
                        import(/* webpackChunkName: "fleetAlerts" */ "./views/FleetAlerts/AlertPolicies.vue"),
                },
                {
                    path: "/dashboard/fleetalerts/AlertList",
                    name: "alertActivityUpdate",
                    component: () =>
                        import(/* webpackChunkName: "fleetAlerts" */ "./views/FleetAlerts/alertList.vue"),
                },
                {
                    path: "/dashboard/servicealerts/Summary",
                    name: "vehicleAlerts",
                    component: () =>
                        import(/* webpackChunkName: "serviceAlerts" */ "./views/VehicleAlerts/vehicleAlertActivity.vue"),
                },
                //{
                //    path: "/dashboard/fleetalerts/newPolicy",
                //    name: "alertActivityCreate",
                //    component: () =>
                //        import(/* webpackChunkName: "fleetAlerts" */ "./views/FleetAlerts/newPolicies.vue"),
                //},
                {
                    path: "/dashboard/serviceAlerts/add",
                    name: "serviceAlertsAdd",
                    component: () =>
                        import(/* webpackChunkName: "serviceAlerts" */ "./views/VehicleAlerts/servicealerts.vue"),
                },
                {
                    path: "/dashboard/fleetalerts/AlertActivity",
                    name: "fleetAlertActivity",
                    component: () =>
                        import(/* webpackChunkName: "fleetAlerts" */ "./views/FleetAlerts/AlertActivity.vue"),
                },
                //{
                //    path: "/dashboard/fleetalerts/edit/:name",
                //    name: "alertActivityUpdate",
                //    component: () =>
                //        import(/* webpackChunkName: "fleetAlerts" */ "./views/FleetAlerts/newPolicies.vue"),
                //},
                {
                    path: "/dashboard/servicealerts/vehicleAlertPolicies",
                    name: "vehicleServiceAlerts",
                    component: () =>
                        import(/* webpackChunkName: "serviceAlerts" */ "./views/VehicleAlerts/VehicleAlertPolicies.vue"),
                },
                {
                    path: "/dashboard/list-report/dashboard/:name?",
                    name: "listReportDashboardRead",
                    component: () =>
                        import(/* webpackChunkName: "reports" */ "./views/reports/reports.vue"),
                },
                //{
                //    path: "/managegroups",
                //    name: "manageGroupsRead",
                //    component: () =>
                //        import(/* webpackChunkName: "groups" */ "./views/ManageGroups/Groups.vue"),
                //},
                {
                    path: "/manageGroupsUpdate",
                    name: "manageGroupsUpdate",
                    component: () =>
                        import(/* webpackChunkName: "groups" */ "./views/Groups/groupManagement.vue"),
                },
                {
                    path: "/myDashboard",
                    name: "myDashboard",
                    //  redirect: "/myDashboard/executiveDashboard",
                    component: () =>
                        import(/* webpackChunkName: "dashboard" */ "./views/Mydashboard/dashboard.vue"),
                    children: [
                        {
                            path: "/myDashboard/executiveDashboard",
                            name: "executiveDashboard",
                            component: () =>
                                import(/* webpackChunkName: "dashboard" */ "./views/Mydashboard/ExecutiveDashboard.vue"),
                        },
                        {
                            path: "/myDashboard/efficiencyDashboard",
                            name: "efficiencyDashboard",
                            component: () =>
                                import(/* webpackChunkName: "dashboard" */ "./views/Mydashboard/EfficiencyDashboard.vue"),
                        },
                        {
                            path: "/myDashboard/driverDashboard",
                            name: "driverDashboard",
                            component: () =>
                                import(/* webpackChunkName: "dashboard" */ "./views/Mydashboard/DriverDashboard.vue"),
                        },
                        {
                            path: "/myDashboard/utilizationDashboard",
                            name: "utilizationDashboard",
                            component: () =>
                                import(/* webpackChunkName: "dashboard" */ "./views/Mydashboard/UtilizationDashboard.vue"),
                        },
                    ],
                },
                {
                    path: "/dashboard/settings/:name?",
                    name: "settings",
                    component: () =>
                        import(/* webpackChunkName: "settings" */ "./views/settings/settings.vue"),
                },
                {
                    path: "/noAction",
                    name: "noAction",
                    component: () =>
                        import(/* webpackChunkName: "noAction" */ "./views/noAction.vue"),
                },
            ],
        },
    ],
});

function getCulture() {
    let culture =
        navigator.languages && navigator.languages.length
            ? navigator.languages[0]
            : navigator.userLanguage ||
            navigator.language ||
            navigator.browserLanguage ||
            "en";
    return culture.split("-")[0];
}

function setLanguage(next) {
    const culture = localStorage.getItem("langCulture");
    const lang = culture == undefined || culture == "" ? getCulture() : culture;
    i18n.loadLanguageAsync(lang).then(() => {
        if (
            Vue.prototype.$session.get("tenantDetails") &&
            Vue.prototype.$session.get("tenantDetails").tenantId
        ) {
            let tenantDetails = Vue.prototype.$session.get("tenantDetails");
            mutations.updateUserName(tenantDetails.displayName);
            const configProperties = Vue.prototype.$session.get("configProperties");
            mutations.updateCompanyName(configProperties.companyInfo[0].companyName);
            const groupFilterStore = useGroupsFilterStore();
            if (groupFilterStore.groups.length > 0) {
                next();
            };
        } else {
            next();
        }
    });
}

function routesAllowedWithoutClaim(name) {
    switch (name) {
        case 'otpLogin':
        case 'login':
        case 'forgotpassword':
        case 'forgotconfirmationpassword':
        case 'resetpassword':
            return true;
            break;
        default:
            return false
    }
}

router.onError(error => {
    if (/loading chunk \d* failed./i.test(error.message)) {
        window.location.reload()
    }
});

router.beforeEach((to, from, next) => {
    const insightsStore = useInsightsStore();
    insightsStore.startTrackPage(to.name);
    const redirectTo = { name: "login" };
    if (routesAllowedWithoutClaim(to.name)) {
        next();
        return;
    } else if (!store.getters['claimsStore/getClaims'].includes(to.name) && store.getters['claimsStore/getClaims'].includes(from.name)) {
        toast.error("You are not authorized for the requested Module. Please contact Administrator.");
        next({ name: from.name });
    } else if (to.name === 'trace' && store.getters['claimsStore/getClaims'].includes('livemapRead')) {
        moment.locale(Vue.localStorage.get('langCulture'));
        if (Vue.prototype.$session.get('tenantDetails').mainTenantId === Vue.prototype.$session.get('tenantDetails').tenantId) {
            setLanguage(next);
        }
    } else if (!store.getters['claimsStore/getClaims'].includes(to.name)) {
        if (to.path?.slice(-3) == 'csv') {
            store.dispatch('report/addFileToDownloadPrompt', to.path.toString());
            if (store.getters['claimsStore/getClaims'].includes("scheduledReports") && Vue.prototype.$session.get('tenantDetails')?.userId) {
                next({ name: "listReportDashboardRead", params: { name: "generatedReports", fileToPrompt: to.path.toString() } });
            } else {
                localStorage.clear();
                next({ name: "login", params: { pendingReport: true, fileToPrompt: to.path.toString() } });
            };      
        } else if (store.getters['claimsStore/getClaims'].includes('QlikSenseRead')) {
            next({ name: "QlikSenseRead" });
        } else if (store.getters['claimsStore/getClaims'].includes('mySettingsRead')) {
            toast.error("Invalid Landing page. Please configure and save one.");
            next({ name: "settings", params: { name: "my" } });
        } else {
            toast.error("You are not authorized for the requested Module. Please contact Administrator.");
            localStorage.clear();
            next(redirectTo);
        }
    } else if (store.getters['claimsStore/getClaims'].includes(to.name)) {
        moment.locale(Vue.localStorage.get('langCulture'));
        if (Vue.prototype.$session.get('tenantDetails').mainTenantId === Vue.prototype.$session.get('tenantDetails').tenantId) {
            setLanguage(next);
        } else {
            if (to.name === "settings") {
                if (store.getters['claimsStore/getClaims'].includes('snapshotRead')) {
                    next({ name: "snapshotRead" });
                } else {
                    next({ name: "livemapRead" });
                };            
            } else {
                setLanguage(next);
            }
        }
    } else if (redirectTo.name != to.name) {
        next(redirectTo);
    }
    next();
});

router.afterEach(route => {
    const url = location.protocol + '//' + location.host + route.fullPath;
    const insightsStore = useInsightsStore();
    insightsStore.stopTrackPage(route.name, url);
})

export default router;
