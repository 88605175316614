<template>
    <div>
        <!-- #region Filter Area -->
        <v-row style="padding: 25px 29px 0 29px; text-align: center;" dense>
            <!-- Filter -->
            <v-col cols="12">
                <v-text-field v-model="searchFilter"
                              placeholder="Filter"
                              id="input_6"
                              solo flat outlined dense hide-details>
                    <template v-slot:append>
                        <v-menu v-model="filterMenu"
                                offset-y
                                min-width="380" max-width="380"
                                :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon
                                       v-bind="attrs"
                                       v-on="on">
                                    <v-icon>mdi-filter</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-btn text outlined block @click="getDefaultSearch">All/None</v-btn>
                                </v-list-item>
                                <v-row no-gutters>
                                    <v-col v-for="(item, index) in alertTypeFilters"
                                           :key="index"
                                           cols="12"
                                           sm="6">
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <kendo-switch v-model="item.search" @change="refreshAlerts"></kendo-switch>
                                            </v-list-item-icon>
                                            <v-list-item-content class="filterMenuItems" style="padding-left: 8px;">
                                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                            </v-list>
                        </v-menu>
                    </template>
                </v-text-field>
            </v-col>
            <!-- Dismiss -->
            <v-col cols="6">
                <v-btn @click="updateAlertStatus(true)"
                       text color="headerButton">
                    <v-icon>mdi-progress-close</v-icon>
                    {{ $t("CustomeViews.dismissPage") }}
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn @click="dismissAllAlerts"
                       text color="headerButton">
                    <v-icon>cancel</v-icon>
                    {{ $t("CustomeViews.dismissAll") }}
                </v-btn>
            </v-col>
        </v-row>
        <!-- #endregion -->
        <!-- #region List Area -->
        <div class="overflow" id="notificationScrollZone" v-show="showList">
            <!-- Title -->
            <v-card flat dense
                    class="titleBar"
                    style=" justify-content: space-between;">
                <v-card-title dense>{{ $t("FleetLabels.fleetAlerts") }}</v-card-title>
                <v-btn plain text small
                       v-show="hasFilter"
                       @click="filterMenu = true"
                       style="font-size: small; justify-content: flex-end; padding-left: 8px;">
                    <v-icon>mdi-filter</v-icon>
                    <v-card-subtitle dense style="padding: 0;" color="primary">({{$t("filterState.FILTERED")}})</v-card-subtitle>
                </v-btn>
            </v-card>
            <!-- Pager -->
            <v-row class="pager" v-if="count > 0">
                <v-col cols="4">
                    <div v-if="currentPage > 1" @click="--currentPage;checkAlertStatus(true)">
                        <span>
                            <v-icon>keyboard_arrow_left</v-icon>
                            {{$t("VehicleAlertActivity.previous")}}
                        </span>
                    </div>
                </v-col>
                <v-col cols="5">
                    <div> Page {{currentPage}} of {{totalPages}} </div>
                </v-col>
                <v-col cols="3">
                    <div v-if="currentPage < totalPages" @click="++currentPage;checkAlertStatus(true)">
                        <span>
                            {{$t("VehicleAlertActivity.next")}}
                            <v-icon>keyboard_arrow_right</v-icon>
                        </span>
                    </div>
                </v-col>
            </v-row>
            <!-- List -->
            <v-list>
                <alertListItem v-for="item in filteredList"
                               :key="item.id"
                               :alertItem="item"
                               selectable
                               @showInfo="showInfo(item)">
                </alertListItem>
            </v-list>
            <!-- Pager -->
            <v-row class="pager" v-if="count > 0">
                <v-col cols="4">
                    <div v-if="currentPage > 1" @click="--currentPage;checkAlertStatus(true)">
                        <span>
                            <v-icon>keyboard_arrow_left</v-icon>
                            {{$t("VehicleAlertActivity.previous")}}
                        </span>
                    </div>
                </v-col>
                <v-col cols="5">
                    <div> Page {{currentPage}} of {{totalPages}} </div>
                </v-col>
                <v-col cols="3">
                    <div v-if="currentPage < totalPages" @click="++currentPage;checkAlertStatus(true)">
                        <span>
                            {{$t("VehicleAlertActivity.next")}}
                            <v-icon>keyboard_arrow_right</v-icon>
                        </span>
                    </div>
                </v-col>
            </v-row>
        </div>
        <!-- #endregion -->
        <!-- #region No Alerts -->
        <v-alert v-if="noShowFlag"
                 prominent
                 text
                 dense
                 color="#40C1AC"
                 style="color: #40C1AC "
                 type="error">
            <template v-slot:prepend>
                <v-icon large
                        style="color: #002B49; padding-right: 12px">
                    mdi-check-all
                </v-icon>
            </template>
            <v-card plain flat dense light
                    style="display: flex; justify-content: space-between; align-items: center; background-color: transparent !important; ">
                <v-card-subtitle color="secondary">
                    {{$t('CustomeViews.noAlertsToShow')}}
                </v-card-subtitle>
                <v-btn plain text small
                       v-show="hasFilter"
                       @click="filterMenu = true"
                       style="font-size: small; justify-content: flex-end; padding-left: 8px;">
                    <v-icon>mdi-filter</v-icon>
                    <v-card-subtitle dense style="padding: 0;" color="primary">({{$t("filterState.FILTERED")}})</v-card-subtitle>
                </v-btn>
            </v-card>
        </v-alert>
        <!-- #endregion -->
        <!-- #region Selected Alert -->
        <div v-if="!showList && count > 0">
            <div class="titleBar">
                <v-btn text block
                       @click="showList = !showList; selectedAlert = null;"
                       style="height: 48px;">
                    <v-icon>mdi-arrow-left</v-icon> {{ $t("FleetLabels.back_to_alerts") }}
                </v-btn>
            </div>
            <alertListItem :alertItem="selectedAlert"
                           showToolbar
                           showMSG
                           @showOnMap="showAlertMapLocation"
                           @showCommentAudit="showCommentAudit">
            </alertListItem>
            <div style="text-align: end; padding-right: 16px; padding-top: 16px; ">
                <v-btn color="primaryButton"
                       @click="updateAlertStatus()"
                       style="height: 48px;">
                    <v-icon>cancel</v-icon>
                    {{$t("FleetLabels.dismissAlert")}}
                </v-btn>
            </div>
        </div>
        <!-- #endregion -->
        <alertAuditDialog ref="alertAuditDialog"></alertAuditDialog>
    </div>
</template>

<script>
    import RuleService from "../../../service/rule.service";
    import Constant from "./../../../../constant";
    import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
    import { debug } from "util";
    import alertListItem from "@/components/fleet-alerts/alertListItem";
    import alertAuditDialog from "@/components/dialogs/alert-policy/alert-audit"

    export default {
        name: "AlertNotification",
        components: {
            HubConnection,
            HubConnectionBuilder,
            alertListItem,
            alertAuditDialog,
        },
        async created() {
            await RuleService.getNotActionCount().then(response => {
                this.totalCount = response
            })
            this.$eventBus.$on('checkAlertStatus', this.checkAlertStatus);
            this.checkAlertStatus(true);
        },
        mounted() {
            this.$eventBus.$on("DismissAll", this.dismissAll);
        },
        data() {
            return {
                filterMenu:false,
                filterUser: "",
                dataList: [],
                count: 0,
                
                
                noActionHit: 0,
                
                currentPage: 1,
                pageSize: 50,
                totalCount: 0,
                filteredCount: 0,
                totalPages: 1,
                
                nextPageToken: [{ pageNum: 1, pageToken: null }],

                //#region Bulk Dismissal
                dismissAll: "",
                //#endregion

                //#region Single Selection
                showList: false,
                selectedAlert: null,
                //#endregion

                //#region Filter
                alertTypeFilters: [
                    { text: this.$tc("FleetLabels.Activity"), value: "Activity", search: true },
                    { text: this.$tc("FleetLabels.DeviceTampering"), value: "DeviceTampering", search: true },
                    { text: this.$tc("FleetLabels.HarshDriving"), value: "HarshDriving", search: true },
                    { text: this.$tc("FleetLabels.Idling"), value: "Idling", search: true },
                    { text: this.$tc("FleetLabels.LongStop"), value: "LongStop", search: true },
                    { text: this.$tc("FleetLabels.Ignition"), value: "Ignition", search: true },
                    { text: this.$tc("FleetLabels.Inactivity"), value: "Inactivity", search: true },
                    { text: this.$tc("FleetLabels.LateStart"), value: "LateStart", search: true },
                    { text: this.$tc("FleetLabels.EarlyArrival"), value: "EarlyArrival", search: true },
                    { text: this.$tc("FleetLabels.NotCheckedIn"), value: "NotCheckedIn", search: true },
                    { text: this.$tc("FleetLabels.Speeding"), value: "Speeding", search: true },
                    { text: this.$tc("FleetLabels.RouteDeviation"), value: "RouteDeviation", search: true },
                //    { text: this.$tc("FleetLabels.location"), value: "Geofence", search: true },
                ],
                searchFilter: "",
                noShowFlag: false,
                //#endregion
            };
        },
        computed: {
            //#region Data Getters
            assetsData() { return this.$assetStore.getAssetsSelectorData(); },
            //#endregion
            filteredList() {
                return this.dataList;
            },
            hasFilter() {
                return this.alertTypeFilters.filter(i => i.search == false).length > 0 ? true : false;
            },
        },
        methods: {
           

            //#region Filter
            getDefaultSearch() {
                let allNone = false;
                if (this.alertTypeFilters.some(item => !item.search)) {
                    allNone = true;
                };
                this.alertTypeFilters.forEach(element => {
                    element.search = allNone;
                });
                this.refreshAlerts();
            },
            //#endregion

            //#region Data Refresh
            refreshAlerts() {
                this.$nextTick(() => {
                    this.currentPage = 1
                    this.nextPageToken = [{ pageNum: 1, pageToken: null }]
                    this.checkAlertStatus(true);
                });
            },
            async alertsDismissed() {
                this.checkAlertStatus();
                RuleService.getNotActionCount().then(response => {
                        this.$emit("count", response);
                        this.totalCount = response
                    }).catch((err) => {
                        this.$emit("count", 0);
                        console.log(err);
                    });

            },
            async checkAlertStatus(scrollToTop = false) {
                let alertType = "null";
                if (this.alertTypeFilters.filter(function (alertTypeFilter) { return alertTypeFilter.search }).length > 0) {
                    alertType = this.alertTypeFilters.filter(function (alertTypeFilter) { return alertTypeFilter.search })
                        .map(function (alertTypeFilter) { return alertTypeFilter.value })
                        .join();
                };
                let overrideParams = {
                    pageSize: this.pageSize,
                    page: this.currentPage,
                    details: `*${this.searchFilter}*`,
                    alertType: alertType,
                    sortCriteria: "-alertGenerateDateTime",
                    nextPageToken: this.nextPageToken[this.currentPage - 1].pageToken
                };
                if (this.searchFilter || this.alertTypeFilters.some(item => !item.search)) {
                    RuleService.getNotActionCount({ details: `*${this.searchFilter}*`, alertType: alertType }).then(response => {
                        this.filteredCount = response;
                    });
                } else {
                    this.filteredCount = this.totalCount;
                };
                await RuleService.getAlertNotification(overrideParams)
                    .then((response) => {
                        this.calculateNotifications(response.data, response.count);
                        if (this.totalPages != response.page && this.nextPageToken[response.page] == undefined) {
                            this.nextPageToken.push({ pageNum: response.page + 1, pageToken: response.nextPageToken });
                        };    
                        if (scrollToTop == true) {
                            try {
                                document.getElementById("notificationScrollZone").children[0].scrollIntoView({ behavior: 'smooth' });
                            } catch (e) { };
                        };
                    })
                    .catch((err) => {
                        if (this.noActionHit < 3) {
                            setTimeout(() => {
                                ++this.noActionHit;
                                this.checkAlertStatus();
                            }, 1000);
                            console.log(err);
                        }
                    });
            },
            getVehicleName(vehicleId,fallback) {
                let asset = this.assetsData.find(i => i.id == vehicleId);
                return asset 
                    ? this.$vehicleInfoFormat(asset, true)
                    : fallback
            },
            calculateNotifications(data, count) {
                this.dataList = data.map(i => ({
                    ...i,
                    vehicleName: this.getVehicleName(i.vehicleId, i.vehicleName),
                }));
                if (this.dataList.length == 0) {
                    this.showList = false;
                    this.noShowFlag = true;
                } else {
                    if (!this.count) {
                        this.showList = true;
                        this.noShowFlag = false;
                    };
                }
                this.totalPages = Math.ceil(this.filteredCount / this.pageSize)
                this.count = count;
                // this.count = this.dataList.length;
                //this.$emit("count", this.count);
            },
            //#endregion


            //#region Actions
            showInfo(item) {
                this.showList = false;
                this.selectedAlert = item;
            },
            dismissAll(messageWindow) {
                this.dismissAll = messageWindow;
                this.updateAlertStatus(this.dismissAll);
            },
            dismissAllAlerts() {
                let alertType = "null"
                if (this.alertTypeFilters.filter(alertTypeFilter => alertTypeFilter.search).length > 0) {
                    alertType = this.alertTypeFilters.filter(alertTypeFilter => alertTypeFilter.search)
                        .map(alertTypeFilter => alertTypeFilter.value)
                        .join()
                }
                let overrideParams = {
                    details: `**`,
                    alertType: alertType,
                    sortCriteria: "-alertGenerateDateTime",
                };

                RuleService.getAlertNotification(overrideParams)
                    .then((response) => {
                        let body = [];
                        if (response.data.length > 0) {
                            this.$dialogEventHub.$emit('openDialog', { component: "dismissAlert", entries: response.data });
                        };
                        //body = response.data.map((item) => ({
                        //    id: item.id,
                        //    actionComment: "dismissed",
                        //}));
                        //if (body.length > 0) {
                        //    RuleService.updateAlertAction(body).then((response) => {
                        //        this.$eventBus.$emit("notificationUpdate", true);

                        //        this.dataList = [];
                        //        this.count = 0;
                        //        this.$emit("count", this.count);
                        //        this.noShowFlag = true;
                        //        this.showList = !this.showList;
                        //        this.selectedAlert = null;
                        //    })
                        //    RuleService.getNotActionCount().then(response => {
                        //        this.$emit("count", response);
                        //        this.totalCount = response
                        //    }).catch((err) => {
                        //        this.$emit("count", 0);
                        //        console.log(err);
                        //    });
                        //}
                    })
                    .catch((err) => {
                        if (this.noActionHit < 3) {
                            setTimeout(() => {
                                ++this.noActionHit;
                                this.checkAlertStatus();
                            }, 1000);
                            console.log(err);
                        }
                    });
            },
            updateAlertStatus(dismissAll = false) {
                if (dismissAll == true) {
                    this.$dialogEventHub.$emit('openDialog', {
                        component: "dismissAlert",
                        entries: this.dataList,
                    });
                } else {
                    if (this.selectedAlert.id) {  
                        this.$dialogEventHub.$emit('openDialog', {
                            component: "dismissAlert",
                            entries: [this.selectedAlert],
                        });
                        this.showList = !this.showList;
                        this.selectedAlert = null;
                    };
                };
            },
            showAlertMapLocation(alert) {
                alert.latitude = alert.latitude || alert.lat || 0;
                alert.longitude = alert.longitude || alert.long || 0;
                this.$dashboardEventHub.$emit("openMapDialog",
                    alert,
                    this.$t("FleetLabels.AlertLocation"),
                );
            },
            showCommentAudit(alert) {
                this.$refs.alertAuditDialog && this.$refs.alertAuditDialog.openDialog(alert);
            },
            //#endregion
        },
        watch: {
            searchFilter: _.debounce(function () {
                this.refreshAlerts()
            }, 800),
        },
        beforeDestroy () {
            this.$eventBus.$off('checkAlertStatus', this.checkAlertStatus);
            this.$eventBus.$off('alertsDismissed', this.alertsDismissed);
            this.$eventBus.$off("DismissAll", this.dismissAll);
        },
    };
</script>
<style scoped>
/*Confirmed Styles*/
    .overflow {
        overflow-y: auto;
        height: calc(100vh - 237px);
    }
    .titleBar {
        background-color: #b4e5dd !important;
        display: flex;
        align-items: center;
       
        height: 48px;
    }
    .pager {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin: 0;
        padding: 0;
        border: 0;
        width: 100%;
        color: #002B49;
    }

        .pager .v-icon {
            color: #002B49;
        }
</style>
