import Vue from 'vue';
import { defineStore } from 'pinia';
import vehicleService from "@/service/vehicles.service";
import locationService from "@/service/locations.service";
import deviceService from "@/service/device.service";
export const useAssetStore = defineStore('assets', {
    state: () => ({
        assets: [],
        assetsLight : [],
        tenantId: "",
        locationsMapped : false,
    }),
    //$assetStore - Sorted by displayName
    actions: {

        /** Reads asset list from backend service and overwrites the current state
         * @param {[String]} ids
         */
        async getAssets(ids = null) {
            if (ids && !Array.isArray(ids)) { ids = [ids] };
            await vehicleService.getAllVehicleSolutionByIds(ids, true).then(async assetsData => {
                if (assetsData) {        
                    this.assets = assetsData
                        .map(asset => ({
                            ...asset,
                            assignedLocations: []
                        }))
                        .sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
                    this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                    this.locationsMapped = false;
                } else {
                    this.assets = [];
                    this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                    this.locationsMapped = false;
                };
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load vehicles');
                this.assets = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                this.locationsMapped = false;
            });
        },

        /** Reads asset list from backend service and overwrites the current state
       * @param {[String]} ids
       */
        async getAssetsWithLocationMapping(ids = null) {
            if (ids && !Array.isArray(ids)) { ids = [ids] };
            await vehicleService.getAllVehicleSolutionByIds(ids, true).then(async assetsData => {
                if (assetsData) {
                    await locationService.getAllVehicleLocationMappingByVehiclePost({
                        'vehicleId': assetsData.map(i => i.id)
                    }).then(response => {
                        assetsData.forEach((asset, index) => {
                            let locationData = response.data.filter(c => c.toAsset == asset.id);
                            if (locationData.length > 0) {
                                assetsData[index].assignedLocations = locationData.map(item => item.fromAsset);
                            } else {
                                assetsData[index].assignedLocations = [];
                            };
                        });
                        this.assets = assetsData.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
                        this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                        this.locationsMapped = true;
                    }).catch((err) => {
                        Vue.prototype.$toast.error('Error Loading Vehicle Mapping');
                        this.assets = [];
                        this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                        this.locationsMapped = false;
                    });
                } else {
                    this.assets = [];
                    this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                    this.locationsMapped = false;
                };
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load vehicles');
                this.assets = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                this.locationsMapped = false;
            });
        },

        async getAssetSelectionData(ids = ['All']) {
            if (ids && !Array.isArray(ids)) { ids = [ids] };
            let payload = { ids: ids }
            await vehicleService.getAssetLight(payload).then(async assetsData => {
                if (assetsData) {
                    this.assetsLight = assetsData
                        .map(asset => ({
                            ...asset,
                            assignedLocations: []
                        }))
                        .sort((a, b) => (a.vehicleName.toLowerCase() > b.vehicleName.toLowerCase() ? 1 : -1));
                    this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                    this.locationsMapped = false;
                } else {
                    this.assetsLight = [];
                    this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                    this.locationsMapped = false;
                };
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load vehicles');
                this.assetsLight = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                this.locationsMapped = false;
            });
        },

        getAssetByID(id) {
           return this.getAssetsSelectorData().find(i => i.id == id);
        },

        /** Clears the current store
         */
        async clearAssetsStore() {
            this.assets = [];
            this.assetsLight = [];
            this.tenantId = "";
        },
        /** Retreive the Store State if the TenantId Matches the store tenant
        * @returns Array of Assets
        */
        getAssetsSelectorData() {
            return this.tenantId == (Vue.prototype.$session.get('tenantDetails')?.tenantId || '')
                ? this.assets.length > 0 
                    ? this.assets 
                    : this.assetsLight
                : [];
        },
        /** Retreive the Store State if the TenantId Matches the store tenant
         * @returns Array of Assets
         */
        getAssetsData() {
            let sessionTenant = (Vue.prototype.$session.get('tenantDetails')?.tenantId || '')
            return this.tenantId == sessionTenant
                ? this.assets
                : [];
        },

        /** Removes operators based on provided ids         
        * @param {[String]} ids
        */
        removeAssetsByIds(ids) {
            if (!Array.isArray(ids)) { ids = [ids] };
            this.assets = [
                ...this.assets
                    .filter(i => !ids.includes(i.id))
                    .sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1))
            ];
        },

        /** Reads Asset records based on ids provided and adds them to the store
         * @param {[String]} ids
         */
        async addAssetsByIds(ids) {
            
            if (!Array.isArray(ids)) { ids = [ids] };
            let self = this;
            await vehicleService.getAllVehicleSolutionByIds(ids, true).then(async assetsData => {
                if (assetsData) {
                    let stripIds = [...(Array.isArray(ids) ? ids : []), ...assetsData.map(i => i.id)];
                    this.assets = [...new Set([
                        ...this.assets.filter(i => !stripIds.includes(i.id)),
                        ...assetsData.map(asset => ({
                            ...asset,
                            assignedLocations: []
                        }))])
                    ].sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
                    this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                    this.locationsMapped = false;
                } else {
                    this.assets = [];
                    this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                    this.locationsMapped = false;
                };
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load vehicles');
                this.assets = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                this.locationsMapped = false;
            });
        },

        /** Reads Asset records based on ids provided and adds them to the store
         * @param {[String]} ids
         */
        async addAssetsByIdsWithLocationsMapped(ids) {
            if (!Array.isArray(ids)) { ids = [ids] };
            let self = this;
            await vehicleService.getAllVehicleSolutionByIds(ids, true).then(async assetsData => {
                if (assetsData) {
                    await locationService.getAllVehicleLocationMappingByVehiclePost({
                        'vehicleId': assetsData.map(i => i.id)
                    }).then(response => {
                        assetsData.forEach((asset, index) => {
                            let locationData = response.data.filter(c => c.toAsset == asset.id);
                            if (locationData.length > 0) {
                                assetsData[index].assignedLocations = locationData.map(item => item.fromAsset);
                            } else {
                                assetsData[index].assignedLocations = [];
                            };
                        });
                        self.assets = [...new Set([
                            ...self.assets.filter(i => !ids.includes(i.id)),
                            ...assetsData
                        ])].sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
                        self.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                        this.locationsMapped = true;
                    }).catch((err) => {
                        Vue.prototype.$toast.error('Error Loading Vehicle Mapping');
                        this.assets = [];
                        this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                        this.locationsMapped = false;
                    });
                } else {
                    this.assets = [];
                    this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                    this.locationsMapped = false;
                };
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load vehicles');
                this.assets = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                this.locationsMapped = false;
            });
        },

        /** Maps Location to Asset*/
        async mapAssetsToLocations() {
            let self = this;
            let targetList = self.assets.filter(i => i.assignedLocations.length == 0).map(i => i.id);

            await locationService.getAllVehicleLocationMappingByVehiclePost({
                'vehicleId': targetList
            }).then(response => {
                targetList.forEach((assetId, index) => {
                    let locationData = response.data.filter(c => c.toAsset == assetId);
                    if (locationData.length > 0) {
                        self.assets.find(i => i.id == assetId).assignedLocations = locationData.map(item => item.fromAsset);
                    };
                });
                self.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                self.locationsMapped = true;
            }).catch((err) => {
                Vue.prototype.$toast.error('Error Loading Vehicle Mapping');
                this.assets = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                this.locationsMapped = false;
            });
        },

        /** Replaces state with provided array
         * @param {[AssetEntry]} assetsData
         */
        replaceAssetState(assetsData) {
            if (!Array.isArray(assetsData)) { assetsData = [assetsData] };
            this.assets = assetsData.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
            this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            this.locationsMapped = false;
        },
        replaceAssetsLightState(assetsData) {
            if (!Array.isArray(assetsData)) { assetsData = [assetsData] };
            this.assetsLight = assetsData;
            this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
        },

        //Delete DeInstall Asset
        async deleteDeinstalledAsset(id) {
            await deviceService.DeleteAsset(id);
        }
    },
})