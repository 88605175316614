<template>
    <v-list-item @click="itemSelected"
                 style="border-bottom: solid thin lightgrey;"
                 :class="[ 'priority' + alert.priority ]">
        <v-list-item-content>
            <v-list-item-title class="alertTypeWithToolbar">
                <strong>{{ alert.alertType }}</strong>
                <div v-if="showToolbar">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="$checkRBACAccess('replayRead')" icon
                                   dark
                                   v-bind="attrs"
                                   v-on="on"
                                   @click.stop="openPrompt(true)">
                                <md-icon :md-src="require(`@/assets/images/replay.icon.svg`)">
                                </md-icon>
                            </v-btn>
                        </template>
                        <span>{{$t("LPD.replay")}}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   v-on="on"
                                   icon small
                                   @click="$emit('showOnMap',alert)">
                                <v-icon>mdi-image-marker</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t("Replay.showonMap")}}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="alert.vehicleId">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   v-on="on"
                                   icon small
                                   @click="$emit('showCommentAudit',alert)">
                                <v-icon>mdi-clipboard-text-clock</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t("FleetLabels.commentAudit")}}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="alert.actionComments.length != 0">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   v-on="on"
                                   icon small
                                   @click="$emit('showCommentHistory',alert)">
                                <v-icon>mdi-format-list-checkbox</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t("FleetLabels.showCommentHistory")}}</span>
                    </v-tooltip>
                </div>
            </v-list-item-title>
            <v-list-item-subtitle v-if="alert.statusText">
                <v-chip small>{{alert.statusText}}</v-chip>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="assetName">
                {{ assetName }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="operatorName">
                {{ operatorName }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
                {{ dateDisplay }}
            </v-list-item-subtitle>
            <template v-if="showMSG">
                <v-list-item-title>
                    <v-alert text shaped
                             class="messageBlock">
                        <strong style="display: block; white-space: pre-wrap;">{{ beautifiedDetails }}</strong>
                    </v-alert>
                </v-list-item-title>
                <v-list-item-subtitle v-if="alert.rulePolicyName" 
                                      class="policyBlock">
                    {{$t("FleetLabels.alertPolicy")}}&nbsp;:&nbsp; <v-chip small @click="openPrompt(false)" class="chipLink">{{alert.rulePolicyName}}</v-chip>
                </v-list-item-subtitle>
            </template>
        </v-list-item-content>
        <v-list-item-action v-if="selectable">
            <v-icon>keyboard_arrow_right</v-icon>
        </v-list-item-action>
        <prompt ref="prompt"
                :title="promptTitle"
                :confirmationMessage="promptBody"
                :notice="$t('FleetLabels.openPolicy.notice')"
                @confirm="promptConfirm">
        </prompt>
    </v-list-item>
</template>
<script>
    import { format } from "date-fns";
    import prompt from "@/components/ConfirmationDialog/SubComponents/genericPrompt"
    import { RuleService } from "@/service/index";
    export default {
        components: {
            prompt,
        },
        props: {
            alertItem: {
                type: Object,
                default() {
                    return {
                        alertType: "",
                        alertGenerateDateTime: new date(),
                        priority: "LOW",
                    }
                },
            },
            selectable: {
                type: Boolean,
                default() {
                    return false;
                },
            },
            showMSG: {
                type: Boolean,
                default() {
                    return false;
                },
            },
            showToolbar: {
                type: Boolean,
                default() {
                    return false;
                },
            },
        },
        data() {
            return {
                localAlert: {},
                promptReplay: false,
            };
        },
        methods: {
            //#region Data Getters
            getOperatorNameFromStore() {
                if (this.alert.driverId) {
                    let operator = this.$driverStore.getOperatorByID(this.alert.driverId);
                    return operator
                        ? operator.displayName
                        : null;
                } else {
                    return null;
                };
            },
            getAssetNameFromStore() {
                if (this.alert.vehicleId) {
                    let asset = this.$assetStore.getAssetByID(this.alert.vehicleId);
                    return asset
                        ? this.$options.filters.vehicleDisplayText(asset)
                        : null;
                } else {
                    return null;
                };
            },
            readFullAlert() {
                if (this.alertItem.id) {
                    RuleService.getAlertsByIds([this.alertItem.id]).then(result => {
                        let alert = result.find(i => i.id == this.alertItem.id);
                        if (alert) {
                            this.localAlert = alert;
                        };
                    }).catch(err => {
                        this.$toast.error(this.$t('FleetLabels.failedToReadAlert'));
                    });
                };
            },
            //#endregion
            //#region Actions
            itemSelected() {
                if (this.selectable) {
                    this.$emit('showInfo', this.alert);
                };
            },
            showRulePolicy() {
                this.$dialogEventHub.$emit('openCreateAlertPolicyDialog', { id: this.alert.rulePolicyId });
                this.$drawerEventHub.$emit("closeMessageDrawer");
                this.$emit('close');
            },
            showReplay() {
                let replayDate = this.alert.alertGenerateDateTime;
                if (isNaN(Date.parse(this.alert.alertGenerateDateTime))) {
                    replayDate = parse(this.alert.alertGenerateDateTime, this.$getUserDateTimeFormat(), new Date())
                };
               
                const data = {
                    name: 'replaydetails',
                    params:
                    {
                        date: [format(new Date(replayDate), "yyyy-MM-dd"), format(new Date(replayDate), "yyyy-MM-dd")],
                        selected: {
                            vehicles: [this.alert.vehicleId]
                        },
                    }
                };
                this.$router.push(data);
                this.$drawerEventHub.$emit("closeMessageDrawer");
                this.$emit('close');
            },
            //#endregion
            //#region Prompt
            openPrompt(isReplay = false) {
                this.promptReplay = isReplay;
                this.$refs.prompt.openDialog(this.alert.rulePolicyId,
                    isReplay
                        ? [this.alert.alertType, this.assetName].join("\n ")
                        : this.alert.rulePolicyName);
            },
            promptConfirm() {
                if (this.promptReplay) {
                    this.showReplay();
                } else {
                    this.showRulePolicy();
                };
            },
            //#endregion
        },
        computed: {
            alert() {
                return ({
                    ...this.alertItem,
                    ...this.localAlert
                });
            },
            //#region Display Fields
            assetName() {
                return this.alert.displayName
                    || this.alert.vehicleName
                    || this.getAssetNameFromStore();
            },
            operatorName() {
                return this.alert.driverDisplayName
                    || this.alert.driverName
                    || this.getOperatorNameFromStore();
            },
            dateDisplay() {
                return isNaN(Date.parse(this.alert.alertGenerateDateTime))
                    ? this.alert.alertGenerateDateTime
                    : this.$formatUTCDateTime(this.alert.alertGenerateDateTime);
            },
            beautifiedDetails() {
                let atText = this.$t("LiveMapLabels.at");
                return this.alert.details
                    .split(". (").join(".\n(")
                    .split([")", atText].join(" ")).join([")\n", atText].join(""))
            },
            //#endregion
            //#region Prompt
            promptTitle() {
                return this.promptReplay
                    ? this.$t('LiveMapLabels.replay')
                    : this.$t('FleetLabels.openPolicy.title')
            },
            promptBody() {
                return this.promptReplay
                    ? this.$t('FleetLabels.showReplay.body')
                    : this.$t('FleetLabels.openPolicy.body')
            },
            //#endregion
        },
        watch: {
            alertItem:
            {
                immediate: true,
                handler(newValue, oldValue) {
                    if (newValue != oldValue) {
                        this.localAlert = {};
                    };
                    //Check if we have sufficient information
                    let requiredFields = ["alertType", "priority", "statusText",];
                    if (Object.keys(newValue).filter(key => requiredFields.includes(key)).length != requiredFields.length) {
                        this.readFullAlert();
                    };
                }
            },
        },
    }
</script>
<style scoped>
    .priorityLOW {
        border-left: solid 8px #40c1ac;
    }

    .priorityMEDIUM {
        border-left: solid 8px darkorange;
    }

    .priorityHIGH {
        border-left: solid 8px red;
    }

    ::v-deep .v-chip__content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
        display: flow;
        margin-top: 4px;
    }

    .messageBlock {
        color: rgb(0, 43, 73) !important;
        border-top-right-radius: 16px;
        margin: 16px 4px;
    }

    .policyBlock {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .chipLink {
        color: #002B49;
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: #002B49;
        text-shadow: 0 0 1px #40C1AC;
    }

    .alertTypeWithToolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .v-list-item__content {
        align-self: self-start;
    }
</style>