<template>
    <v-card>
        <v-card-title v-if="showHeader"
                      style="padding-bottom: 0px; display: flex; justify-content: space-between; padding-right: 8px;">
            {{$t('FleetLabels.commentAudit')}}
            <v-tooltip bottom v-if="filteredResults.length != 0">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon
                           v-bind="attrs"
                           v-on="on"
                           @click.stop="exportComments">
                        <v-icon>mdi-export-variant</v-icon>
                    </v-btn>
                </template>
                <span>{{$t("DriverLabels.exportToexcel")}}</span>
            </v-tooltip>
        </v-card-title>
        <v-card-text v-if="!loading"
                     style="padding-bottom: 0;">
            <v-text-field v-model="search"
                          append-icon="mdi-magnify"
                          :label="$t('GroupLable.search')"
                          single-line clearable
                          hide-details
                          style="padding-top: 0;">
            </v-text-field>
        </v-card-text>
        <v-list expand
                :class="[scrollOnOverflow ? 'overflow' : 'noOverflow']">
            <v-list-group v-for="alertEntry in filteredResults" :key="alertEntry.id"
                          value="true"
                          class="v-list-Header">
                <template v-slot:activator>
                    <v-list-item-title class="splitDisplay">
                        <span style=" font-size: small;">
                            {{ dateDisplay(alertEntry.alertGenerateDateTime) }}<br />
                            <strong>{{ alertEntry.alertType }}</strong>
                        </span>
                        <v-chip small style="background-color: #002b49; color: #fff;">{{alertEntry.statusText}}</v-chip>
                    </v-list-item-title>
                </template>
                <v-list-item>
                    <v-list-item-content>
                        <commentHistory :alertItem="alertEntry"
                                        dense>
                        </commentHistory>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
            <v-list-item v-if="filteredResults.length == 0 && !loading">
                <v-list-item-content style="display: flex; flex-direction: column;">
                    <v-icon style="padding-right:8px;">mdi-tooltip-question-outline</v-icon> {{$t('FleetLabels.noCommentsFound')}}
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="loading"
                         style="height: 226px; overflow: hidden; padding-top:24px;">
                <v-list-item-content>
                    <v-progress-circular indeterminate
                                         :size="200"
                                         :width="20"
                                         color="#002b49">
                    </v-progress-circular>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>
<script>
    import { RuleService } from "@/service/index";
    import { format, startOfDay, endOfDay, subDays } from "date-fns";
    import commentHistory from "@/components/fleet-alerts/comment-history";
    import { saveExcel } from '@progress/kendo-vue-excel-export';
    export default {
        props: {
            alertItem: {
                type: Object,
                default() {
                    return {};
                },
            },
            showHeader: {
                type: Boolean,
                default() {
                    return false;
                },
            },
            scrollOnOverflow: {
                type: Boolean,
                default() {
                    return false;
                },
            },
        },
        components: {
            commentHistory,
        },
        data() {
            return {
                commentList: [],
                loading: false,
                search: '',
            }
        },
        methods: {
            getAuditHistory() {
                this.loading = true;
                RuleService.getAlertAudit(
                    startOfDay(subDays(new Date(this.alertItem.alertGenerateDateTime), 7)),
                    endOfDay(new Date(this.alertItem.alertGenerateDateTime)),
                    this.alertItem.vehicleId,
                ).then(response => {
                    response.data = response.data.filter(i => (i.vehicleId == this.alertItem.vehicleId
                        || i.driverId == this.alertItem.driverId) && i.actionComments.length != 0);
                    this.commentList = response.data.sort((a, b) => { return a.alertGenerateDateTime < b.alertGenerateDateTime ? 1 : -1 }) || [];
                    this.$nextTick(() => {
                        this.loading = false;
                    });
                }).catch(err => {
                    this.$toast.error(this.$t("FleetLabels.failedToReadHistory"))
                    this.$nextTick(() => {
                        this.loading = false;
                        this.$emit('fail');
                    });
                });
            },
            exportComments() {
                saveExcel({
                    data: this.filteredResults.reduce((response, entry) => {
                        if (Array.isArray(entry.actionComments)) {
                            entry.actionComments.forEach(comment => {
                                let user = comment.createdBy
                                    ? (
                                        this.getUsersData.find(user => user.id == comment.createdBy)
                                        || this.getAllUsersData.find(user => user.id == comment.createdBy)
                                    )
                                    : null;
                                response.push({
                                    alertGenerateDateTime: this.dateDisplay(entry.alertGenerateDateTime),
                                    alertType: entry.alertType,
                                    commentDateTime: comment.commentDateTime
                                        ? this.$formatUTCDateTime(comment.commentDateTime * 1000)
                                        : '',
                                    createdBy: user
                                        ? user.displayName
                                        : comment.createdBy || '',
                                    comment: comment.comment,
                                });
                            });
                        } else if (entry.actionComment) {
                            response.push({
                                alertGenerateDateTime: entry.alertGenerateDateTime,
                                alertType: entry.alertType,
                                commentDateTime: '',
                                createdBy: '',
                                comment: entry.actionComment,
                            });
                        };
                        return response;
                    }, []),
                    fileName: "export",
                    columns: [
                        { field: 'alertGenerateDateTime', title: this.$t('FleetLabels.Time') },
                        { field: 'alertType', title: this.$t('FleetLabels.AlertType') },
                        { field: 'commentDateTime', title: this.$t('FleetLabels.commentDateTime') },
                        { field: 'createdBy', title: this.$t('FleetLabels.commentedBy') },
                        { field: 'comment', title: this.$t('jobList.changeState.comment') },
                    ],
                    avoidLinks: true
                });
            },
            dateDisplay(alertGenerateDateTime) {
                return isNaN(Date.parse(alertGenerateDateTime))
                    ? this.$formatUTCDateTime(this.$moment(new Date('20' + alertGenerateDateTime + ' UTC')).toISOString())
                    : this.$formatUTCDateTime(alertGenerateDateTime);
            },
        },
        computed: {
            getAllUsersData() {
                return this.$userStore.getAllUsersData();
            },
            getUsersData() {
                return this.$userStore.getUsersData();
            },

            filteredResults() {
                return this.search
                    ? this.commentList.map(alert => ({
                        ...alert,
                        actionComments: alert.actionComments.filter(entry => entry.comment.toLowerCase().includes(this.search.toLowerCase())) || [],
                    })).filter(alert => alert.actionComments.length > 0)
                    : this.commentList.filter(alert => alert.actionComments.length > 0)
            },
        },
        watch: {
            alertItem:
            {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                    if (newValue != oldValue && newValue) {
                        this.search = '';
                        this.loading = true;
                        this.commentList = [];
                        this.getAuditHistory();
                    };
                }
            },
        },
    }
</script>
<style scoped>
    .v-list {
        background-color: transparent;
    }
    .overflow {
        max-height: 330px;
        overflow-y: auto;
    }
    .noOverflow {
        max-height: 58vh;
        overflow-y: auto;
    }
    .v-list-Header {
        background-color: #b4e5dd !important;
    }
        .v-list-Header > :first-child {
            position: sticky;
            top: -8px;
            z-index: 1;
            background-color: #b4e5dd !important;
        }
    ::v-deep .v-list-group__header__append-icon {
        min-width: 36px !important;
    }
    [role=listitem] {
        background-color: #f6f8f8;
        padding: 0;
    }

        [role=listitem] > .v-list-item__content {
            padding: 0;
        }

    .splitDisplay {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>